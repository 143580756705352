/* eslint-disable */
// sequence-relayer v0.4.1 da20208d66be29ad86d2662ca38c4425bc5910f8
// --
// Code generated by webrpc-gen@v0.22.0 with typescript generator. DO NOT EDIT.
//
// webrpc-gen -schema=relayer.ridl -target=typescript -client -out=./clients/relayer.gen.ts

export const WebrpcHeader = 'Webrpc'

export const WebrpcHeaderValue = 'webrpc@v0.22.0;gen-typescript@v0.16.1;sequence-relayer@v0.4.1'

// WebRPC description and code-gen version
export const WebRPCVersion = 'v1'

// Schema version of your RIDL schema
export const WebRPCSchemaVersion = 'v0.4.1'

// Schema hash generated from your RIDL schema
export const WebRPCSchemaHash = 'da20208d66be29ad86d2662ca38c4425bc5910f8'

type WebrpcGenVersions = {
  webrpcGenVersion: string
  codeGenName: string
  codeGenVersion: string
  schemaName: string
  schemaVersion: string
}

export function VersionFromHeader(headers: Headers): WebrpcGenVersions {
  const headerValue = headers.get(WebrpcHeader)
  if (!headerValue) {
    return {
      webrpcGenVersion: '',
      codeGenName: '',
      codeGenVersion: '',
      schemaName: '',
      schemaVersion: ''
    }
  }

  return parseWebrpcGenVersions(headerValue)
}

function parseWebrpcGenVersions(header: string): WebrpcGenVersions {
  const versions = header.split(';')
  if (versions.length < 3) {
    return {
      webrpcGenVersion: '',
      codeGenName: '',
      codeGenVersion: '',
      schemaName: '',
      schemaVersion: ''
    }
  }

  const [_, webrpcGenVersion] = versions[0].split('@')
  const [codeGenName, codeGenVersion] = versions[1].split('@')
  const [schemaName, schemaVersion] = versions[2].split('@')

  return {
    webrpcGenVersion,
    codeGenName,
    codeGenVersion,
    schemaName,
    schemaVersion
  }
}

//
// Types
//

export enum ETHTxnStatus {
  UNKNOWN = 'UNKNOWN',
  DROPPED = 'DROPPED',
  QUEUED = 'QUEUED',
  SENT = 'SENT',
  SUCCEEDED = 'SUCCEEDED',
  PARTIALLY_FAILED = 'PARTIALLY_FAILED',
  FAILED = 'FAILED'
}

export enum TransferType {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
  BRIDGE_DEPOSIT = 'BRIDGE_DEPOSIT',
  BRIDGE_WITHDRAW = 'BRIDGE_WITHDRAW',
  BURN = 'BURN',
  UNKNOWN = 'UNKNOWN'
}

export enum FeeTokenType {
  UNKNOWN = 'UNKNOWN',
  ERC20_TOKEN = 'ERC20_TOKEN',
  ERC1155_TOKEN = 'ERC1155_TOKEN'
}

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC'
}

export interface Version {
  webrpcVersion: string
  schemaVersion: string
  schemaHash: string
  appVersion: string
}

export interface RuntimeStatus {
  healthOK: boolean
  startTime: string
  uptime: number
  ver: string
  branch: string
  commitHash: string
  chainID: number
  useEIP1559: boolean
  senders: Array<SenderStatus>
  checks: RuntimeChecks
}

export interface SenderStatus {
  index: number
  address: string
  etherBalance: number
  active: boolean
}

export interface RuntimeChecks {}

export interface SequenceContext {
  factory: string
  mainModule: string
  mainModuleUpgradable: string
  guestModule: string
  utils: string
}

export interface GasTank {
  id: number
  chainId: number
  name: string
  currentBalance: number
  unlimited: boolean
  feeMarkupFactor: number
  updatedAt: string
  createdAt: string
}

export interface GasTankBalanceAdjustment {
  gasTankId: number
  nonce: number
  amount: number
  totalBalance: number
  balanceTimestamp: string
  createdAt: string
}

export interface GasSponsor {
  id: number
  gasTankId: number
  projectId: number
  chainId: number
  address: string
  name: string
  active: boolean
  updatedAt: string
  createdAt: string
  deletedAt: string
}

export interface GasSponsorUsage {
  name: string
  id: number
  totalGasUsed: number
  totalTxnFees: number
  totalTxnFeesUsd: number
  avgGasPrice: number
  totalTxns: number
  startTime: string
  endTime: string
}

export interface MetaTxn {
  walletAddress: string
  contract: string
  input: string
}

export interface MetaTxnLog {
  id: number
  chainId: number
  projectId: number
  txnHash: string
  txnNonce: string
  metaTxnID?: string
  txnStatus: ETHTxnStatus
  txnRevertReason: string
  requeues: number
  queuedAt: string
  sentAt: string
  minedAt: string
  target: string
  input: string
  txnArgs: { [key: string]: any }
  txnReceipt?: { [key: string]: any }
  walletAddress: string
  metaTxnNonce: string
  gasLimit: number
  gasPrice: string
  gasUsed: number
  gasEstimated: number
  gasFeeMarkup?: number
  usdRate: string
  creditsUsed: number
  cost: string
  isWhitelisted: boolean
  gasSponsor?: number
  gasTank?: number
  updatedAt: string
  createdAt: string
}

export interface MetaTxnReceipt {
  id: string
  status: string
  revertReason?: string
  index: number
  logs: Array<MetaTxnReceiptLog>
  receipts: Array<MetaTxnReceipt>
  txnReceipt: string
}

export interface MetaTxnReceiptLog {
  address: string
  topics: Array<string>
  data: string
}

export interface Transaction {
  txnHash?: string
  blockNumber: number
  chainId: number
  metaTxnID?: string
  transfers?: Array<TxnLogTransfer>
  users?: { [key: string]: TxnLogUser }
  timestamp: string
}

export interface TxnLogUser {
  username: string
}

export interface TxnLogTransfer {
  transferType: TransferType
  contractAddress: string
  from: string
  to: string
  ids: Array<string>
  amounts: Array<string>
}

export interface SentTransactionsFilter {
  pending?: boolean
  failed?: boolean
}

export interface SimulateResult {
  executed: boolean
  succeeded: boolean
  result?: string
  reason?: string
  gasUsed: number
  gasLimit: number
}

export interface FeeOption {
  token: FeeToken
  to: string
  value: string
  gasLimit: number
}

export interface FeeToken {
  chainId: number
  name: string
  symbol: string
  type: FeeTokenType
  decimals?: number
  logoURL: string
  contractAddress?: string
  tokenID?: string
}

export interface Page {
  pageSize?: number
  page?: number
  more?: boolean
  totalRecords?: number
  column?: string
  before?: any
  after?: any
  sort?: Array<SortBy>
}

export interface SortBy {
  column: string
  order: SortOrder
}

export interface Relayer {
  ping(headers?: object, signal?: AbortSignal): Promise<PingReturn>
  version(headers?: object, signal?: AbortSignal): Promise<VersionReturn>
  runtimeStatus(headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn>
  getSequenceContext(headers?: object, signal?: AbortSignal): Promise<GetSequenceContextReturn>
  getChainID(headers?: object, signal?: AbortSignal): Promise<GetChainIDReturn>
  sendMetaTxn(args: SendMetaTxnArgs, headers?: object, signal?: AbortSignal): Promise<SendMetaTxnReturn>
  getMetaTxnNonce(args: GetMetaTxnNonceArgs, headers?: object, signal?: AbortSignal): Promise<GetMetaTxnNonceReturn>
  getMetaTxnReceipt(args: GetMetaTxnReceiptArgs, headers?: object, signal?: AbortSignal): Promise<GetMetaTxnReceiptReturn>
  simulate(args: SimulateArgs, headers?: object, signal?: AbortSignal): Promise<SimulateReturn>
  updateMetaTxnGasLimits(
    args: UpdateMetaTxnGasLimitsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateMetaTxnGasLimitsReturn>
  feeTokens(headers?: object, signal?: AbortSignal): Promise<FeeTokensReturn>
  feeOptions(args: FeeOptionsArgs, headers?: object, signal?: AbortSignal): Promise<FeeOptionsReturn>
  getMetaTxnNetworkFeeOptions(
    args: GetMetaTxnNetworkFeeOptionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetMetaTxnNetworkFeeOptionsReturn>
  getMetaTransactions(args: GetMetaTransactionsArgs, headers?: object, signal?: AbortSignal): Promise<GetMetaTransactionsReturn>
  getTransactionCost(args: GetTransactionCostArgs, headers?: object, signal?: AbortSignal): Promise<GetTransactionCostReturn>
  sentTransactions(args: SentTransactionsArgs, headers?: object, signal?: AbortSignal): Promise<SentTransactionsReturn>
  pendingTransactions(args: PendingTransactionsArgs, headers?: object, signal?: AbortSignal): Promise<PendingTransactionsReturn>
  getGasTank(args: GetGasTankArgs, headers?: object, signal?: AbortSignal): Promise<GetGasTankReturn>
  addGasTank(args: AddGasTankArgs, headers?: object, signal?: AbortSignal): Promise<AddGasTankReturn>
  updateGasTank(args: UpdateGasTankArgs, headers?: object, signal?: AbortSignal): Promise<UpdateGasTankReturn>
  nextGasTankBalanceAdjustmentNonce(
    args: NextGasTankBalanceAdjustmentNonceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<NextGasTankBalanceAdjustmentNonceReturn>
  adjustGasTankBalance(
    args: AdjustGasTankBalanceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AdjustGasTankBalanceReturn>
  getGasTankBalanceAdjustment(
    args: GetGasTankBalanceAdjustmentArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetGasTankBalanceAdjustmentReturn>
  listGasTankBalanceAdjustments(
    args: ListGasTankBalanceAdjustmentsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListGasTankBalanceAdjustmentsReturn>
  listGasSponsors(args: ListGasSponsorsArgs, headers?: object, signal?: AbortSignal): Promise<ListGasSponsorsReturn>
  getGasSponsor(args: GetGasSponsorArgs, headers?: object, signal?: AbortSignal): Promise<GetGasSponsorReturn>
  addGasSponsor(args: AddGasSponsorArgs, headers?: object, signal?: AbortSignal): Promise<AddGasSponsorReturn>
  updateGasSponsor(args: UpdateGasSponsorArgs, headers?: object, signal?: AbortSignal): Promise<UpdateGasSponsorReturn>
  removeGasSponsor(args: RemoveGasSponsorArgs, headers?: object, signal?: AbortSignal): Promise<RemoveGasSponsorReturn>
  addressGasSponsors(args: AddressGasSponsorsArgs, headers?: object, signal?: AbortSignal): Promise<AddressGasSponsorsReturn>
  getProjectBalance(args: GetProjectBalanceArgs, headers?: object, signal?: AbortSignal): Promise<GetProjectBalanceReturn>
  adjustProjectBalance(
    args: AdjustProjectBalanceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AdjustProjectBalanceReturn>
}

export interface PingArgs {}

export interface PingReturn {
  status: boolean
}
export interface VersionArgs {}

export interface VersionReturn {
  version: Version
}
export interface RuntimeStatusArgs {}

export interface RuntimeStatusReturn {
  status: RuntimeStatus
}
export interface GetSequenceContextArgs {}

export interface GetSequenceContextReturn {
  data: SequenceContext
}
export interface GetChainIDArgs {}

export interface GetChainIDReturn {
  chainID: number
}
export interface SendMetaTxnArgs {
  call: MetaTxn
  quote?: string
  projectID?: number
}

export interface SendMetaTxnReturn {
  status: boolean
  txnHash: string
}
export interface GetMetaTxnNonceArgs {
  walletContractAddress: string
  space?: string
}

export interface GetMetaTxnNonceReturn {
  nonce: string
}
export interface GetMetaTxnReceiptArgs {
  metaTxID: string
}

export interface GetMetaTxnReceiptReturn {
  receipt: MetaTxnReceipt
}
export interface SimulateArgs {
  wallet: string
  transactions: string
}

export interface SimulateReturn {
  results: Array<SimulateResult>
}
export interface UpdateMetaTxnGasLimitsArgs {
  walletAddress: string
  walletConfig: any
  payload: string
}

export interface UpdateMetaTxnGasLimitsReturn {
  payload: string
}
export interface FeeTokensArgs {}

export interface FeeTokensReturn {
  isFeeRequired: boolean
  tokens: Array<FeeToken>
}
export interface FeeOptionsArgs {
  wallet: string
  to: string
  data: string
  simulate?: boolean
}

export interface FeeOptionsReturn {
  options: Array<FeeOption>
  sponsored: boolean
  quote?: string
}
export interface GetMetaTxnNetworkFeeOptionsArgs {
  walletConfig: any
  payload: string
}

export interface GetMetaTxnNetworkFeeOptionsReturn {
  options: Array<FeeOption>
}
export interface GetMetaTransactionsArgs {
  projectId: number
  page?: Page
}

export interface GetMetaTransactionsReturn {
  page: Page
  transactions: Array<MetaTxnLog>
}
export interface GetTransactionCostArgs {
  projectId: number
  from: string
  to: string
}

export interface GetTransactionCostReturn {
  cost: number
}
export interface SentTransactionsArgs {
  filter?: SentTransactionsFilter
  page?: Page
}

export interface SentTransactionsReturn {
  page: Page
  transactions: Array<Transaction>
}
export interface PendingTransactionsArgs {
  page?: Page
}

export interface PendingTransactionsReturn {
  page: Page
  transactions: Array<Transaction>
}
export interface GetGasTankArgs {
  id: number
}

export interface GetGasTankReturn {
  gasTank: GasTank
}
export interface AddGasTankArgs {
  name: string
  feeMarkupFactor: number
  unlimited?: boolean
}

export interface AddGasTankReturn {
  status: boolean
  gasTank: GasTank
}
export interface UpdateGasTankArgs {
  id: number
  name?: string
  feeMarkupFactor?: number
  unlimited?: boolean
}

export interface UpdateGasTankReturn {
  status: boolean
  gasTank: GasTank
}
export interface NextGasTankBalanceAdjustmentNonceArgs {
  id: number
}

export interface NextGasTankBalanceAdjustmentNonceReturn {
  nonce: number
}
export interface AdjustGasTankBalanceArgs {
  id: number
  nonce: number
  amount: number
}

export interface AdjustGasTankBalanceReturn {
  status: boolean
  adjustment: GasTankBalanceAdjustment
}
export interface GetGasTankBalanceAdjustmentArgs {
  id: number
  nonce: number
}

export interface GetGasTankBalanceAdjustmentReturn {
  adjustment: GasTankBalanceAdjustment
}
export interface ListGasTankBalanceAdjustmentsArgs {
  id: number
  page?: Page
}

export interface ListGasTankBalanceAdjustmentsReturn {
  page: Page
  adjustments: Array<GasTankBalanceAdjustment>
}
export interface ListGasSponsorsArgs {
  projectId: number
  page?: Page
}

export interface ListGasSponsorsReturn {
  page: Page
  gasSponsors: Array<GasSponsor>
}
export interface GetGasSponsorArgs {
  projectId: number
  id: number
}

export interface GetGasSponsorReturn {
  gasSponsor: GasSponsor
}
export interface AddGasSponsorArgs {
  projectId: number
  address: string
  name?: string
  active?: boolean
}

export interface AddGasSponsorReturn {
  status: boolean
  gasSponsor: GasSponsor
}
export interface UpdateGasSponsorArgs {
  projectId: number
  id: number
  name?: string
  active?: boolean
}

export interface UpdateGasSponsorReturn {
  status: boolean
  gasSponsor: GasSponsor
}
export interface RemoveGasSponsorArgs {
  projectId: number
  id: number
}

export interface RemoveGasSponsorReturn {
  status: boolean
}
export interface AddressGasSponsorsArgs {
  address: string
  page?: Page
}

export interface AddressGasSponsorsReturn {
  page: Page
  gasSponsors: Array<GasSponsor>
}
export interface GetProjectBalanceArgs {
  projectId: number
}

export interface GetProjectBalanceReturn {
  balance: number
}
export interface AdjustProjectBalanceArgs {
  projectId: number
  amount: number
  identifier: string
}

export interface AdjustProjectBalanceReturn {
  balance: number
}

//
// Client
//
export class Relayer implements Relayer {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/Relayer/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname.replace(/\/*$/, '')
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  ping = (headers?: object, signal?: AbortSignal): Promise<PingReturn> => {
    return this.fetch(this.url('Ping'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  version = (headers?: object, signal?: AbortSignal): Promise<VersionReturn> => {
    return this.fetch(this.url('Version'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            version: <Version>_data.version
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  runtimeStatus = (headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn> => {
    return this.fetch(this.url('RuntimeStatus'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <RuntimeStatus>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSequenceContext = (headers?: object, signal?: AbortSignal): Promise<GetSequenceContextReturn> => {
    return this.fetch(this.url('GetSequenceContext'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            data: <SequenceContext>_data.data
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getChainID = (headers?: object, signal?: AbortSignal): Promise<GetChainIDReturn> => {
    return this.fetch(this.url('GetChainID'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            chainID: <number>_data.chainID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sendMetaTxn = (args: SendMetaTxnArgs, headers?: object, signal?: AbortSignal): Promise<SendMetaTxnReturn> => {
    return this.fetch(this.url('SendMetaTxn'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            txnHash: <string>_data.txnHash
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getMetaTxnNonce = (args: GetMetaTxnNonceArgs, headers?: object, signal?: AbortSignal): Promise<GetMetaTxnNonceReturn> => {
    return this.fetch(this.url('GetMetaTxnNonce'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            nonce: <string>_data.nonce
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getMetaTxnReceipt = (args: GetMetaTxnReceiptArgs, headers?: object, signal?: AbortSignal): Promise<GetMetaTxnReceiptReturn> => {
    return this.fetch(this.url('GetMetaTxnReceipt'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            receipt: <MetaTxnReceipt>_data.receipt
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  simulate = (args: SimulateArgs, headers?: object, signal?: AbortSignal): Promise<SimulateReturn> => {
    return this.fetch(this.url('Simulate'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            results: <Array<SimulateResult>>_data.results
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateMetaTxnGasLimits = (
    args: UpdateMetaTxnGasLimitsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateMetaTxnGasLimitsReturn> => {
    return this.fetch(this.url('UpdateMetaTxnGasLimits'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            payload: <string>_data.payload
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  feeTokens = (headers?: object, signal?: AbortSignal): Promise<FeeTokensReturn> => {
    return this.fetch(this.url('FeeTokens'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isFeeRequired: <boolean>_data.isFeeRequired,
            tokens: <Array<FeeToken>>_data.tokens
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  feeOptions = (args: FeeOptionsArgs, headers?: object, signal?: AbortSignal): Promise<FeeOptionsReturn> => {
    return this.fetch(this.url('FeeOptions'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            options: <Array<FeeOption>>_data.options,
            sponsored: <boolean>_data.sponsored,
            quote: <string>_data.quote
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getMetaTxnNetworkFeeOptions = (
    args: GetMetaTxnNetworkFeeOptionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetMetaTxnNetworkFeeOptionsReturn> => {
    return this.fetch(this.url('GetMetaTxnNetworkFeeOptions'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            options: <Array<FeeOption>>_data.options
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getMetaTransactions = (
    args: GetMetaTransactionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetMetaTransactionsReturn> => {
    return this.fetch(this.url('GetMetaTransactions'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            transactions: <Array<MetaTxnLog>>_data.transactions
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTransactionCost = (
    args: GetTransactionCostArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTransactionCostReturn> => {
    return this.fetch(this.url('GetTransactionCost'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            cost: <number>_data.cost
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sentTransactions = (args: SentTransactionsArgs, headers?: object, signal?: AbortSignal): Promise<SentTransactionsReturn> => {
    return this.fetch(this.url('SentTransactions'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            transactions: <Array<Transaction>>_data.transactions
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  pendingTransactions = (
    args: PendingTransactionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<PendingTransactionsReturn> => {
    return this.fetch(this.url('PendingTransactions'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            transactions: <Array<Transaction>>_data.transactions
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getGasTank = (args: GetGasTankArgs, headers?: object, signal?: AbortSignal): Promise<GetGasTankReturn> => {
    return this.fetch(this.url('GetGasTank'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            gasTank: <GasTank>_data.gasTank
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addGasTank = (args: AddGasTankArgs, headers?: object, signal?: AbortSignal): Promise<AddGasTankReturn> => {
    return this.fetch(this.url('AddGasTank'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            gasTank: <GasTank>_data.gasTank
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateGasTank = (args: UpdateGasTankArgs, headers?: object, signal?: AbortSignal): Promise<UpdateGasTankReturn> => {
    return this.fetch(this.url('UpdateGasTank'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            gasTank: <GasTank>_data.gasTank
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  nextGasTankBalanceAdjustmentNonce = (
    args: NextGasTankBalanceAdjustmentNonceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<NextGasTankBalanceAdjustmentNonceReturn> => {
    return this.fetch(this.url('NextGasTankBalanceAdjustmentNonce'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            nonce: <number>_data.nonce
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  adjustGasTankBalance = (
    args: AdjustGasTankBalanceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AdjustGasTankBalanceReturn> => {
    return this.fetch(this.url('AdjustGasTankBalance'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            adjustment: <GasTankBalanceAdjustment>_data.adjustment
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getGasTankBalanceAdjustment = (
    args: GetGasTankBalanceAdjustmentArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetGasTankBalanceAdjustmentReturn> => {
    return this.fetch(this.url('GetGasTankBalanceAdjustment'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            adjustment: <GasTankBalanceAdjustment>_data.adjustment
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listGasTankBalanceAdjustments = (
    args: ListGasTankBalanceAdjustmentsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListGasTankBalanceAdjustmentsReturn> => {
    return this.fetch(this.url('ListGasTankBalanceAdjustments'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            adjustments: <Array<GasTankBalanceAdjustment>>_data.adjustments
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listGasSponsors = (args: ListGasSponsorsArgs, headers?: object, signal?: AbortSignal): Promise<ListGasSponsorsReturn> => {
    return this.fetch(this.url('ListGasSponsors'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            gasSponsors: <Array<GasSponsor>>_data.gasSponsors
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getGasSponsor = (args: GetGasSponsorArgs, headers?: object, signal?: AbortSignal): Promise<GetGasSponsorReturn> => {
    return this.fetch(this.url('GetGasSponsor'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            gasSponsor: <GasSponsor>_data.gasSponsor
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addGasSponsor = (args: AddGasSponsorArgs, headers?: object, signal?: AbortSignal): Promise<AddGasSponsorReturn> => {
    return this.fetch(this.url('AddGasSponsor'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            gasSponsor: <GasSponsor>_data.gasSponsor
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateGasSponsor = (args: UpdateGasSponsorArgs, headers?: object, signal?: AbortSignal): Promise<UpdateGasSponsorReturn> => {
    return this.fetch(this.url('UpdateGasSponsor'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            gasSponsor: <GasSponsor>_data.gasSponsor
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeGasSponsor = (args: RemoveGasSponsorArgs, headers?: object, signal?: AbortSignal): Promise<RemoveGasSponsorReturn> => {
    return this.fetch(this.url('RemoveGasSponsor'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addressGasSponsors = (
    args: AddressGasSponsorsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddressGasSponsorsReturn> => {
    return this.fetch(this.url('AddressGasSponsors'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            gasSponsors: <Array<GasSponsor>>_data.gasSponsors
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getProjectBalance = (args: GetProjectBalanceArgs, headers?: object, signal?: AbortSignal): Promise<GetProjectBalanceReturn> => {
    return this.fetch(this.url('GetProjectBalance'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            balance: <number>_data.balance
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  adjustProjectBalance = (
    args: AdjustProjectBalanceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AdjustProjectBalanceReturn> => {
    return this.fetch(this.url('AdjustProjectBalance'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            balance: <number>_data.balance
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}

const createHTTPRequest = (body: object = {}, headers: object = {}, signal: AbortSignal | null = null): object => {
  const reqHeaders: { [key: string]: string } = { ...headers, 'Content-Type': 'application/json' }
  reqHeaders[WebrpcHeader] = WebrpcHeaderValue

  return {
    method: 'POST',
    headers: reqHeaders,
    body: JSON.stringify(body || {}),
    signal
  }
}

const buildResponse = (res: Response): Promise<any> => {
  return res.text().then(text => {
    let data
    try {
      data = JSON.parse(text)
    } catch (error) {
      let message = ''
      if (error instanceof Error) {
        message = error.message
      }
      throw WebrpcBadResponseError.new({
        status: res.status,
        cause: `JSON.parse(): ${message}: response text: ${text}`
      })
    }
    if (!res.ok) {
      const code: number = typeof data.code === 'number' ? data.code : 0
      throw (webrpcErrorByCode[code] || WebrpcError).new(data)
    }
    return data
  })
}

//
// Errors
//

export class WebrpcError extends Error {
  name: string
  code: number
  message: string
  status: number
  cause?: string

  /** @deprecated Use message instead of msg. Deprecated in webrpc v0.11.0. */
  msg: string

  constructor(name: string, code: number, message: string, status: number, cause?: string) {
    super(message)
    this.name = name || 'WebrpcError'
    this.code = typeof code === 'number' ? code : 0
    this.message = message || `endpoint error ${this.code}`
    this.msg = this.message
    this.status = typeof status === 'number' ? status : 0
    this.cause = cause
    Object.setPrototypeOf(this, WebrpcError.prototype)
  }

  static new(payload: any): WebrpcError {
    return new this(payload.error, payload.code, payload.message || payload.msg, payload.status, payload.cause)
  }
}

// Webrpc errors

export class WebrpcEndpointError extends WebrpcError {
  constructor(
    name: string = 'WebrpcEndpoint',
    code: number = 0,
    message: string = 'endpoint error',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcEndpointError.prototype)
  }
}

export class WebrpcRequestFailedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcRequestFailed',
    code: number = -1,
    message: string = 'request failed',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcRequestFailedError.prototype)
  }
}

export class WebrpcBadRouteError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRoute',
    code: number = -2,
    message: string = 'bad route',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRouteError.prototype)
  }
}

export class WebrpcBadMethodError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadMethod',
    code: number = -3,
    message: string = 'bad method',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadMethodError.prototype)
  }
}

export class WebrpcBadRequestError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRequest',
    code: number = -4,
    message: string = 'bad request',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRequestError.prototype)
  }
}

export class WebrpcBadResponseError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadResponse',
    code: number = -5,
    message: string = 'bad response',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadResponseError.prototype)
  }
}

export class WebrpcServerPanicError extends WebrpcError {
  constructor(
    name: string = 'WebrpcServerPanic',
    code: number = -6,
    message: string = 'server panic',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcServerPanicError.prototype)
  }
}

export class WebrpcInternalErrorError extends WebrpcError {
  constructor(
    name: string = 'WebrpcInternalError',
    code: number = -7,
    message: string = 'internal error',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcInternalErrorError.prototype)
  }
}

export class WebrpcClientDisconnectedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcClientDisconnected',
    code: number = -8,
    message: string = 'client disconnected',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcClientDisconnectedError.prototype)
  }
}

export class WebrpcStreamLostError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamLost',
    code: number = -9,
    message: string = 'stream lost',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamLostError.prototype)
  }
}

export class WebrpcStreamFinishedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamFinished',
    code: number = -10,
    message: string = 'stream finished',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamFinishedError.prototype)
  }
}

// Schema errors

export class UnauthorizedError extends WebrpcError {
  constructor(
    name: string = 'Unauthorized',
    code: number = 1000,
    message: string = 'Unauthorized access',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnauthorizedError.prototype)
  }
}

export class PermissionDeniedError extends WebrpcError {
  constructor(
    name: string = 'PermissionDenied',
    code: number = 1001,
    message: string = 'Permission denied',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, PermissionDeniedError.prototype)
  }
}

export class SessionExpiredError extends WebrpcError {
  constructor(
    name: string = 'SessionExpired',
    code: number = 1002,
    message: string = 'Session expired',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, SessionExpiredError.prototype)
  }
}

export class MethodNotFoundError extends WebrpcError {
  constructor(
    name: string = 'MethodNotFound',
    code: number = 1003,
    message: string = 'Method not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, MethodNotFoundError.prototype)
  }
}

export class RequestConflictError extends WebrpcError {
  constructor(
    name: string = 'RequestConflict',
    code: number = 1004,
    message: string = 'Conflict with target resource',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RequestConflictError.prototype)
  }
}

export class AbortedError extends WebrpcError {
  constructor(
    name: string = 'Aborted',
    code: number = 1005,
    message: string = 'Request aborted',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AbortedError.prototype)
  }
}

export class GeoblockedError extends WebrpcError {
  constructor(
    name: string = 'Geoblocked',
    code: number = 1006,
    message: string = 'Geoblocked region',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, GeoblockedError.prototype)
  }
}

export class RateLimitedError extends WebrpcError {
  constructor(
    name: string = 'RateLimited',
    code: number = 1007,
    message: string = 'Rate-limited. Please slow down.',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RateLimitedError.prototype)
  }
}

export class ProjectNotFoundError extends WebrpcError {
  constructor(
    name: string = 'ProjectNotFound',
    code: number = 1008,
    message: string = 'Project not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, ProjectNotFoundError.prototype)
  }
}

export class AccessKeyNotFoundError extends WebrpcError {
  constructor(
    name: string = 'AccessKeyNotFound',
    code: number = 1101,
    message: string = 'Access key not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AccessKeyNotFoundError.prototype)
  }
}

export class AccessKeyMismatchError extends WebrpcError {
  constructor(
    name: string = 'AccessKeyMismatch',
    code: number = 1102,
    message: string = 'Access key mismatch',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AccessKeyMismatchError.prototype)
  }
}

export class InvalidOriginError extends WebrpcError {
  constructor(
    name: string = 'InvalidOrigin',
    code: number = 1103,
    message: string = 'Invalid origin for Access Key',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidOriginError.prototype)
  }
}

export class InvalidServiceError extends WebrpcError {
  constructor(
    name: string = 'InvalidService',
    code: number = 1104,
    message: string = 'Service not enabled for Access key',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidServiceError.prototype)
  }
}

export class UnauthorizedUserError extends WebrpcError {
  constructor(
    name: string = 'UnauthorizedUser',
    code: number = 1105,
    message: string = 'Unauthorized user',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnauthorizedUserError.prototype)
  }
}

export class QuotaExceededError extends WebrpcError {
  constructor(
    name: string = 'QuotaExceeded',
    code: number = 1200,
    message: string = 'Quota request exceeded',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QuotaExceededError.prototype)
  }
}

export class QuotaRateLimitError extends WebrpcError {
  constructor(
    name: string = 'QuotaRateLimit',
    code: number = 1201,
    message: string = 'Quota rate limit exceeded',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QuotaRateLimitError.prototype)
  }
}

export class NoDefaultKeyError extends WebrpcError {
  constructor(
    name: string = 'NoDefaultKey',
    code: number = 1300,
    message: string = 'No default access key found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, NoDefaultKeyError.prototype)
  }
}

export class MaxAccessKeysError extends WebrpcError {
  constructor(
    name: string = 'MaxAccessKeys',
    code: number = 1301,
    message: string = 'Access keys limit reached',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, MaxAccessKeysError.prototype)
  }
}

export class AtLeastOneKeyError extends WebrpcError {
  constructor(
    name: string = 'AtLeastOneKey',
    code: number = 1302,
    message: string = 'You need at least one Access Key',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AtLeastOneKeyError.prototype)
  }
}

export class TimeoutError extends WebrpcError {
  constructor(
    name: string = 'Timeout',
    code: number = 1900,
    message: string = 'Request timed out',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, TimeoutError.prototype)
  }
}

export class InvalidArgumentError extends WebrpcError {
  constructor(
    name: string = 'InvalidArgument',
    code: number = 2001,
    message: string = 'Invalid argument',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidArgumentError.prototype)
  }
}

export class UnavailableError extends WebrpcError {
  constructor(
    name: string = 'Unavailable',
    code: number = 2002,
    message: string = 'Unavailable resource',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnavailableError.prototype)
  }
}

export class QueryFailedError extends WebrpcError {
  constructor(
    name: string = 'QueryFailed',
    code: number = 2003,
    message: string = 'Query failed',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QueryFailedError.prototype)
  }
}

export class NotFoundError extends WebrpcError {
  constructor(
    name: string = 'NotFound',
    code: number = 3000,
    message: string = 'Resource not found',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}

export class InsufficientFeeError extends WebrpcError {
  constructor(
    name: string = 'InsufficientFee',
    code: number = 3004,
    message: string = 'Insufficient fee',
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InsufficientFeeError.prototype)
  }
}

export enum errors {
  WebrpcEndpoint = 'WebrpcEndpoint',
  WebrpcRequestFailed = 'WebrpcRequestFailed',
  WebrpcBadRoute = 'WebrpcBadRoute',
  WebrpcBadMethod = 'WebrpcBadMethod',
  WebrpcBadRequest = 'WebrpcBadRequest',
  WebrpcBadResponse = 'WebrpcBadResponse',
  WebrpcServerPanic = 'WebrpcServerPanic',
  WebrpcInternalError = 'WebrpcInternalError',
  WebrpcClientDisconnected = 'WebrpcClientDisconnected',
  WebrpcStreamLost = 'WebrpcStreamLost',
  WebrpcStreamFinished = 'WebrpcStreamFinished',
  Unauthorized = 'Unauthorized',
  PermissionDenied = 'PermissionDenied',
  SessionExpired = 'SessionExpired',
  MethodNotFound = 'MethodNotFound',
  RequestConflict = 'RequestConflict',
  Aborted = 'Aborted',
  Geoblocked = 'Geoblocked',
  RateLimited = 'RateLimited',
  ProjectNotFound = 'ProjectNotFound',
  AccessKeyNotFound = 'AccessKeyNotFound',
  AccessKeyMismatch = 'AccessKeyMismatch',
  InvalidOrigin = 'InvalidOrigin',
  InvalidService = 'InvalidService',
  UnauthorizedUser = 'UnauthorizedUser',
  QuotaExceeded = 'QuotaExceeded',
  QuotaRateLimit = 'QuotaRateLimit',
  NoDefaultKey = 'NoDefaultKey',
  MaxAccessKeys = 'MaxAccessKeys',
  AtLeastOneKey = 'AtLeastOneKey',
  Timeout = 'Timeout',
  InvalidArgument = 'InvalidArgument',
  Unavailable = 'Unavailable',
  QueryFailed = 'QueryFailed',
  NotFound = 'NotFound',
  InsufficientFee = 'InsufficientFee'
}

export enum WebrpcErrorCodes {
  WebrpcEndpoint = 0,
  WebrpcRequestFailed = -1,
  WebrpcBadRoute = -2,
  WebrpcBadMethod = -3,
  WebrpcBadRequest = -4,
  WebrpcBadResponse = -5,
  WebrpcServerPanic = -6,
  WebrpcInternalError = -7,
  WebrpcClientDisconnected = -8,
  WebrpcStreamLost = -9,
  WebrpcStreamFinished = -10,
  Unauthorized = 1000,
  PermissionDenied = 1001,
  SessionExpired = 1002,
  MethodNotFound = 1003,
  RequestConflict = 1004,
  Aborted = 1005,
  Geoblocked = 1006,
  RateLimited = 1007,
  ProjectNotFound = 1008,
  AccessKeyNotFound = 1101,
  AccessKeyMismatch = 1102,
  InvalidOrigin = 1103,
  InvalidService = 1104,
  UnauthorizedUser = 1105,
  QuotaExceeded = 1200,
  QuotaRateLimit = 1201,
  NoDefaultKey = 1300,
  MaxAccessKeys = 1301,
  AtLeastOneKey = 1302,
  Timeout = 1900,
  InvalidArgument = 2001,
  Unavailable = 2002,
  QueryFailed = 2003,
  NotFound = 3000,
  InsufficientFee = 3004
}

export const webrpcErrorByCode: { [code: number]: any } = {
  [0]: WebrpcEndpointError,
  [-1]: WebrpcRequestFailedError,
  [-2]: WebrpcBadRouteError,
  [-3]: WebrpcBadMethodError,
  [-4]: WebrpcBadRequestError,
  [-5]: WebrpcBadResponseError,
  [-6]: WebrpcServerPanicError,
  [-7]: WebrpcInternalErrorError,
  [-8]: WebrpcClientDisconnectedError,
  [-9]: WebrpcStreamLostError,
  [-10]: WebrpcStreamFinishedError,
  [1000]: UnauthorizedError,
  [1001]: PermissionDeniedError,
  [1002]: SessionExpiredError,
  [1003]: MethodNotFoundError,
  [1004]: RequestConflictError,
  [1005]: AbortedError,
  [1006]: GeoblockedError,
  [1007]: RateLimitedError,
  [1008]: ProjectNotFoundError,
  [1101]: AccessKeyNotFoundError,
  [1102]: AccessKeyMismatchError,
  [1103]: InvalidOriginError,
  [1104]: InvalidServiceError,
  [1105]: UnauthorizedUserError,
  [1200]: QuotaExceededError,
  [1201]: QuotaRateLimitError,
  [1300]: NoDefaultKeyError,
  [1301]: MaxAccessKeysError,
  [1302]: AtLeastOneKeyError,
  [1900]: TimeoutError,
  [2001]: InvalidArgumentError,
  [2002]: UnavailableError,
  [2003]: QueryFailedError,
  [3000]: NotFoundError,
  [3004]: InsufficientFeeError
}

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>
