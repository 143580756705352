/* eslint-disable */
// sequence-api v0.4.0 2409c101bc62a1b7aa797e099d913b1c4046e9f6
// --
// Code generated by webrpc-gen@v0.24.0 with typescript generator. DO NOT EDIT.
//
// webrpc-gen -schema=api.ridl -target=typescript -client -out=./clients/api.gen.ts

export const WebrpcHeader = 'Webrpc'

export const WebrpcHeaderValue = 'webrpc@v0.24.0;gen-typescript@v0.16.3;sequence-api@v0.4.0'

// WebRPC description and code-gen version
export const WebRPCVersion = 'v1'

// Schema version of your RIDL schema
export const WebRPCSchemaVersion = 'v0.4.0'

// Schema hash generated from your RIDL schema
export const WebRPCSchemaHash = '2409c101bc62a1b7aa797e099d913b1c4046e9f6'

type WebrpcGenVersions = {
  webrpcGenVersion: string
  codeGenName: string
  codeGenVersion: string
  schemaName: string
  schemaVersion: string
}

export function VersionFromHeader(headers: Headers): WebrpcGenVersions {
  const headerValue = headers.get(WebrpcHeader)
  if (!headerValue) {
    return {
      webrpcGenVersion: '',
      codeGenName: '',
      codeGenVersion: '',
      schemaName: '',
      schemaVersion: ''
    }
  }

  return parseWebrpcGenVersions(headerValue)
}

function parseWebrpcGenVersions(header: string): WebrpcGenVersions {
  const versions = header.split(';')
  if (versions.length < 3) {
    return {
      webrpcGenVersion: '',
      codeGenName: '',
      codeGenVersion: '',
      schemaName: '',
      schemaVersion: ''
    }
  }

  const [_, webrpcGenVersion] = versions[0]!.split('@')
  const [codeGenName, codeGenVersion] = versions[1]!.split('@')
  const [schemaName, schemaVersion] = versions[2]!.split('@')

  return {
    webrpcGenVersion: webrpcGenVersion ?? '',
    codeGenName: codeGenName ?? '',
    codeGenVersion: codeGenVersion ?? '',
    schemaName: schemaName ?? '',
    schemaVersion: schemaVersion ?? ''
  }
}

//
// Types
//

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC'
}

export enum SardinePaymentType {
  ach = 'ach',
  debit = 'debit',
  credit = 'credit',
  us_debit = 'us_debit',
  international_debit = 'international_debit',
  international_credit = 'international_credit'
}

export enum SardineQuoteType {
  buy = 'buy',
  sell = 'sell'
}

export enum TokenType {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155'
}

export enum TransakBuySell {
  UNKNOWN = 'UNKNOWN',
  BUY = 'BUY',
  SELL = 'SELL'
}

export interface Version {
  webrpcVersion: string
  schemaVersion: string
  schemaHash: string
  appVersion: string
}

export interface RuntimeStatus {
  healthOK: boolean
  startTime: string
  uptime: number
  ver: string
  branch: string
  commitHash: string
  checks: RuntimeChecks
  numTxnsRelayed: { [key: string]: NumTxnsRelayed }
}

export interface NumTxnsRelayed {
  chainID: number
  prev: number
  current: number
  period: number
}

export interface RuntimeChecks {}

export interface SequenceContext {
  factory: string
  mainModule: string
  mainModuleUpgradable: string
  guestModule: string
  utils: string
}

export interface PublicKey {
  id: string
  x: string
  y: string
}

export interface User {
  address: string
  username: string
  avatar: string
  bio: string
  location: string
  locale: string
  backup?: boolean
  backupConfirmed?: boolean
  maxInvites?: number
  updatedAt?: string
  createdAt?: string
}

export interface WalletBackup {
  accountAddress: string
  secretHash: string
  encryptedWallet: string
  userConfirmed: boolean
  updatedAt?: string
  createdAt?: string
}

export interface Friend {
  id: number
  userAddress: string
  friendAddress: string
  nickname: string
  user?: User
  createdAt?: string
}

export interface InviteCode {
  usesLeft: number
  ownerAccount: string
  email?: string
  url: string
  createdAt?: string
  expiresAt?: string
}

export interface InviteCodeAccount {
  claimedByUserAddress: string
  claimedAt?: string
}

export interface InviteInfo {
  expiryInHours: number
  max: number
  invites: Array<InviteCode>
}

export interface ContractCall {
  signature: string
  function: string
  args: Array<TupleComponent>
}

export interface TupleComponent {
  name?: string
  type: string
  value: any
}

export interface IntentPrecondition {
  type: string
  chainID: string
  data: any
}

export interface IntentSolution {
  transactions: Array<Transactions>
}

export interface Transactions {
  chainID: string
  transactions: Array<Transaction>
  preconditions?: Array<IntentPrecondition>
}

export interface Transaction {
  delegateCall: boolean
  revertOnError: boolean
  gasLimit: string
  target: string
  value: string
  data: string
}

export interface UserStorage {
  userAddress: string
  key: string
  value: any
}

export interface Token {
  chainId: number
  contractAddress: string
  tokenId?: string
}

export interface Price {
  value: number
  currency: string
}

export interface TokenPrice {
  token: Token
  price?: Price
  price24hChange?: Price
  floorPrice: Price
  buyPrice: Price
  sellPrice: Price
  updatedAt: string
}

export interface ExchangeRate {
  name: string
  symbol: string
  value: number
  vsCurrency: string
  currencyType: string
}

export interface LinkedWallet {
  id: number
  walletType?: string
  walletAddress: string
  linkedWalletAddress: string
  createdAt?: string
}

export interface Page {
  pageSize?: number
  page?: number
  totalRecords?: number
  column?: string
  before?: any
  after?: any
  sort?: Array<SortBy>
  more?: boolean
}

export interface SortBy {
  column: string
  order: SortOrder
}

export interface SardineNFTCheckoutParams {
  name: string
  imageUrl: string
  network: string
  recipientAddress: string
  blockchainNftId: string
  contractAddress: string
  quantity: number
  decimals?: number
  tokenAmount: string
  tokenAddress: string
  tokenSymbol: string
  tokenDecimals?: number
  calldata: string
  platform: string
  approvedSpenderAddress?: string
}

export interface SardineNFTCheckout {
  token: string
  expiresAt: string
  orderId: string
}

export interface SardineOrder {
  id: string
  createdAt?: string
  referenceId: string
  status: string
  fiatCurrency: string
  fiatExchangeRateUSD: number
  transactionId: string
  expiresAt?: string
  total: number
  subTotal: number
  transactionFee: number
  networkFee: number
  paymentCurrency?: string
  paymentMethodType?: string
  transactionType: string
  name: string
  price: number
  imageUrl: string
  contractAddress?: string
  transactionHash?: string
  recipientAddress: string
}

export interface SardineRegion {
  countryCode: string
  isAllowedOnRamp: boolean
  isAllowedOnNFT: boolean
  isBasicKycRequired: Array<string>
  isSsnRequired: Array<string>
  name: string
  currencyCode: string
  isPayrollSupported: boolean
  supportedDocuments: Array<string>
  paymentMethods: Array<SardineRegionPaymentMethod>
  states: Array<SardineRegionState>
}

export interface SardineRegionPaymentMethod {
  name: string
  isAllowedOnRamp: boolean
  isAllowedOnNFT: boolean
  subTypes: Array<string>
  type: string
  subType: string
}

export interface SardineRegionState {
  code: string
  name: string
  isAllowedOnRamp: boolean
  isAllowedOnNFT: boolean
}

export interface SardineSupportedToken {
  network: string
  assetSymbol: string
  assetName: string
  chainId: string
  tokenName: string
  token: string
  tokenAddress: string
}

export interface SardineSupportedTokenForSwap {
  isSupported: boolean
  isSupportedForAbstraction: boolean
  currentBalance: string
}

export interface SardineEnabledToken {
  network: string
  assetSymbol: string
  assetName: string
  chainId: string
  tokenName: string
  token: string
  tokenAddress: string
}

export interface SardineGetQuoteParams {
  assetType: string
  network: string
  total: number
  currency?: string
  paymentType?: SardinePaymentType
  quoteType?: SardineQuoteType
  walletAddress?: string
}

export interface SardineQuote {
  quantity: number
  network: string
  assetType: string
  total: number
  currency: string
  expiresAt: string
  paymentType: string
  price: number
  subtotal: number
  transactionFee: number
  networkFee: number
  highNetworkFee: boolean
  minTransactionValue: number
  maxTransactionValue: number
  liquidityProvider: string
}

export interface SardineFiatCurrency {
  currencyCode: string
  name: string
  currencySymbol: string
  paymentOptions: Array<SardinePaymentOption>
  supportingCountries: Array<string>
}

export interface SardinePaymentOption {
  name: string
  dailyLimit: number
  weeklyLimit: number
  monthlyLimit: number
  maxAmount: number
  minAmount: number
  subTypes: Array<string>
  type: string
  subType: string
  processingTime: string
}

export interface SwapPermit2Price {
  currencyAddress: string
  currencyBalance: string
  price: string
  maxPrice: string
  transactionValue: string
}

export interface SwapPermit2Quote {
  currencyAddress: string
  currencyBalance: string
  price: string
  maxPrice: string
  to: string
  transactionData: string
  transactionValue: string
  approveData: string
}

export interface SwapPrice {
  currencyAddress: string
  currencyBalance: string
  price: string
  maxPrice: string
  transactionValue: string
}

export interface SwapQuote {
  currencyAddress: string
  currencyBalance: string
  price: string
  maxPrice: string
  to: string
  transactionData: string
  transactionValue: string
  approveData: string
}

export interface CurrencyGroup {
  name: string
  tokens: Array<CurrencyGroupToken>
}

export interface CurrencyGroupToken {
  chainId: number
  tokenAddress: string
}

export interface OffchainInventory {
  id: number
  projectId: number
  chainId: number
  externalProductId: string
  paymentTokenAddress: string
  paymentTokenType: TokenType
  paymentTokenId: number
  paymentAmount: number
  paymentRecipient: string
  chainedCallAddress?: string
  chainedCallData?: string
  allowCrossChainPayments?: boolean
  callbackURL?: string
  createdAt: string
  deletedAt?: string
}

export interface OffchainPayment {
  id: number
  offchainInventoryId: number
  productRecipient: string
  paymentChainId: number
  paymentTokenAddress: string
  expiration: string
  createdAt: string
  completedAt?: string
  processedAt?: string
}

export interface PaymentResponse {
  paymentId: number
  offchainInventoryId: number
  chainId: number
  externalProductId: string
  paymentTokenAddress: string
  paymentTokenType: TokenType
  paymentTokenId: number
  paymentTotal: number
  expiration: string
  signature: string
  txTo: string
  txData: string
}

export interface AdoptedChildWallet {
  address: string
}

export interface Pack {
  id: number
  chainId: number
  projectId: number
  contractAddress: string
  content: Array<PackContent>
  createdAt?: string
}

export interface PackContent {
  tokenAddresses: Array<string>
  tokenIds: Array<Array<string>>
  amounts: Array<Array<string>>
}

export interface TransakCountry {
  alpha2: string
  alpha3: string
  isAllowed: boolean
  isLightKycAllowed: boolean
  name: string
  currencyCode: string
  supportedDocuments: Array<string>
  partners: Array<TransakPartner>
  states: Array<TransakState>
}

export interface TransakPartner {
  name: string
  isCardPayment: boolean
  currencyCode: string
}

export interface TransakState {
  code: string
  name: string
  isAllowed: boolean
}

export interface TransakCryptoCurrency {
  id: string
  coinID: string
  address: string
  addressAdditionalData: any
  createdAt: string
  decimals: number
  image: TransakCryptoCurrencyImage
  isAllowed: boolean
  isPopular: boolean
  isStable: boolean
  name: string
  roundOff: number
  symbol: string
  isIgnorePriceVerification: boolean
  imageBk: TransakCryptoCurrencyImage
  kycCountriesNotSupported: Array<string>
  network: TransakCryptoCurrencyNetwork
  uniqueID: string
  tokenType: string
  tokenIdentifier: string
  isPayInAllowed: boolean
  isSuspended: boolean
}

export interface TransakCryptoCurrencyImage {
  large: string
  small: string
  thumb: string
}

export interface TransakCryptoCurrencyNetwork {
  name: string
  fiatCurrenciesNotSupported: Array<any>
  chainID: string
}

export interface TransakCryptoCurrencyNetworkFiatNotSupported {
  fiatCurrency: string
  paymentMethod: string
}

export interface TransakFiatCurrency {
  symbol: string
  supportingCountries: Array<string>
  logoSymbol: string
  name: string
  paymentOptions: Array<TransakFiatCurrencyPaymentOption>
  isPopular: boolean
  isAllowed: boolean
  roundOff: number
  isPayOutAllowed: boolean
  defaultCountryForNFT: string
  icon: string
  displayMessage: string
}

export interface TransakFiatCurrencyPaymentOption {
  name: string
  id: string
  isNftAllowed: boolean
  isNonCustodial: boolean
  processingTime: string
  displayText: boolean
  icon: string
  limitCurrency: string
  isActive: boolean
  provider: string
  maxAmount: number
  minAmount: number
  defaultAmount: number
  isConverted: boolean
  visaPayoutCountries: Array<string>
  mastercardPayoutCountries: Array<string>
  isPayOutAllowed: boolean
  minAmountForPayOut: number
  maxAmountForPayOut: number
  defaultAmountForPayOut: number
}

export interface TransakPrice {
  quoteID: string
  conversionPrice: number
  marketConversionPrice: number
  slippage: number
  fiatCurrency: string
  cryptoCurrency: string
  paymentMethod: string
  fiatAmount: number
  cryptoAmount: number
  isBuyOrSell: string
  network: string
  feeDecimal: number
  totalFee: number
  feeBreakdown: Array<TransakPriceFeeBreakdown>
  nonce: number
  cryptoLiquidityProvider: string
  notes: Array<any>
}

export interface TransakPriceFeeBreakdown {
  Name: string
  Value: number
  ID: string
  Ids: Array<string>
}

export interface TransakGetPriceParams {
  fiatCurrency: string
  cryptoCurrency: string
  isBuyOrSell: TransakBuySell
  network: string
  paymentMethod: string
  fiatAmount: number
  cryptoAmount: number
  quoteCountryCode: string
}

export interface TransakChain {
  name: string
  chainId: number
}

export interface API {
  ping(headers?: object, signal?: AbortSignal): Promise<PingReturn>
  version(headers?: object, signal?: AbortSignal): Promise<VersionReturn>
  runtimeStatus(headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn>
  clock(headers?: object, signal?: AbortSignal): Promise<ClockReturn>
  getSequenceContext(headers?: object, signal?: AbortSignal): Promise<GetSequenceContextReturn>
  getAuthToken(args: GetAuthTokenArgs, headers?: object, signal?: AbortSignal): Promise<GetAuthTokenReturn>
  getAuthToken2(args: GetAuthToken2Args, headers?: object, signal?: AbortSignal): Promise<GetAuthToken2Return>
  sendPasswordlessLink(
    args: SendPasswordlessLinkArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SendPasswordlessLinkReturn>
  registerPublicKey(args: RegisterPublicKeyArgs, headers?: object, signal?: AbortSignal): Promise<RegisterPublicKeyReturn>
  getPublicKey(args: GetPublicKeyArgs, headers?: object, signal?: AbortSignal): Promise<GetPublicKeyReturn>
  friendList(args: FriendListArgs, headers?: object, signal?: AbortSignal): Promise<FriendListReturn>
  getFriendByAddress(args: GetFriendByAddressArgs, headers?: object, signal?: AbortSignal): Promise<GetFriendByAddressReturn>
  searchFriends(args: SearchFriendsArgs, headers?: object, signal?: AbortSignal): Promise<SearchFriendsReturn>
  addFriend(args: AddFriendArgs, headers?: object, signal?: AbortSignal): Promise<AddFriendReturn>
  updateFriendNickname(
    args: UpdateFriendNicknameArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateFriendNicknameReturn>
  removeFriend(args: RemoveFriendArgs, headers?: object, signal?: AbortSignal): Promise<RemoveFriendReturn>
  contractCall(args: ContractCallArgs, headers?: object, signal?: AbortSignal): Promise<ContractCallReturn>
  decodeContractCall(args: DecodeContractCallArgs, headers?: object, signal?: AbortSignal): Promise<DecodeContractCallReturn>
  lookupContractCallSelectors(
    args: LookupContractCallSelectorsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<LookupContractCallSelectorsReturn>
  userStorageFetch(args: UserStorageFetchArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageFetchReturn>
  userStorageSave(args: UserStorageSaveArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageSaveReturn>
  userStorageDelete(args: UserStorageDeleteArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageDeleteReturn>
  userStorageFetchAll(args: UserStorageFetchAllArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageFetchAllReturn>
  getMoonpayLink(args: GetMoonpayLinkArgs, headers?: object, signal?: AbortSignal): Promise<GetMoonpayLinkReturn>
  resolveENSAddress(args: ResolveENSAddressArgs, headers?: object, signal?: AbortSignal): Promise<ResolveENSAddressReturn>
  isValidSignature(args: IsValidSignatureArgs, headers?: object, signal?: AbortSignal): Promise<IsValidSignatureReturn>
  isValidMessageSignature(
    args: IsValidMessageSignatureArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidMessageSignatureReturn>
  isValidTypedDataSignature(
    args: IsValidTypedDataSignatureArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidTypedDataSignatureReturn>
  isValidETHAuthProof(args: IsValidETHAuthProofArgs, headers?: object, signal?: AbortSignal): Promise<IsValidETHAuthProofReturn>
  sardineGetClientToken(headers?: object, signal?: AbortSignal): Promise<SardineGetClientTokenReturn>
  sardineGetNFTCheckoutToken(
    args: SardineGetNFTCheckoutTokenArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SardineGetNFTCheckoutTokenReturn>
  sardineGetNFTCheckoutOrderStatus(
    args: SardineGetNFTCheckoutOrderStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SardineGetNFTCheckoutOrderStatusReturn>
  sardineGetSupportedRegions(headers?: object, signal?: AbortSignal): Promise<SardineGetSupportedRegionsReturn>
  sardineGetSupportedFiatCurrencies(headers?: object, signal?: AbortSignal): Promise<SardineGetSupportedFiatCurrenciesReturn>
  sardineGetSupportedTokens(headers?: object, signal?: AbortSignal): Promise<SardineGetSupportedTokensReturn>
  sardineGetSupportedTokenForSwap(
    args: SardineGetSupportedTokenForSwapArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SardineGetSupportedTokenForSwapReturn>
  sardineGetEnabledTokens(headers?: object, signal?: AbortSignal): Promise<SardineGetEnabledTokensReturn>
  sardineGetQuote(args: SardineGetQuoteArgs, headers?: object, signal?: AbortSignal): Promise<SardineGetQuoteReturn>
  getSardineClientToken(headers?: object, signal?: AbortSignal): Promise<GetSardineClientTokenReturn>
  getSardineNFTCheckoutToken(
    args: GetSardineNFTCheckoutTokenArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSardineNFTCheckoutTokenReturn>
  getSardineNFTCheckoutOrderStatus(
    args: GetSardineNFTCheckoutOrderStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSardineNFTCheckoutOrderStatusReturn>
  transakGetCountries(headers?: object, signal?: AbortSignal): Promise<TransakGetCountriesReturn>
  transakGetCryptoCurrencies(headers?: object, signal?: AbortSignal): Promise<TransakGetCryptoCurrenciesReturn>
  transakGetFiatCurrencies(headers?: object, signal?: AbortSignal): Promise<TransakGetFiatCurrenciesReturn>
  transakGetPrice(args: TransakGetPriceArgs, headers?: object, signal?: AbortSignal): Promise<TransakGetPriceReturn>
  transakGetSupportedNFTCheckoutChains(
    headers?: object,
    signal?: AbortSignal
  ): Promise<TransakGetSupportedNFTCheckoutChainsReturn>
  getCoinPrices(args: GetCoinPricesArgs, headers?: object, signal?: AbortSignal): Promise<GetCoinPricesReturn>
  getCollectiblePrices(
    args: GetCollectiblePricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetCollectiblePricesReturn>
  getExchangeRate(args: GetExchangeRateArgs, headers?: object, signal?: AbortSignal): Promise<GetExchangeRateReturn>
  memoryStore(args: MemoryStoreArgs, headers?: object, signal?: AbortSignal): Promise<MemoryStoreReturn>
  memoryLoad(args: MemoryLoadArgs, headers?: object, signal?: AbortSignal): Promise<MemoryLoadReturn>
  getInviteInfo(headers?: object, signal?: AbortSignal): Promise<GetInviteInfoReturn>
  isValidAccessCode(args: IsValidAccessCodeArgs, headers?: object, signal?: AbortSignal): Promise<IsValidAccessCodeReturn>
  internalClaimAccessCode(
    args: InternalClaimAccessCodeArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<InternalClaimAccessCodeReturn>
  blockNumberAtTime(args: BlockNumberAtTimeArgs, headers?: object, signal?: AbortSignal): Promise<BlockNumberAtTimeReturn>
  paperSessionSecret(args: PaperSessionSecretArgs, headers?: object, signal?: AbortSignal): Promise<PaperSessionSecretReturn>
  paperSessionSecret2(args: PaperSessionSecret2Args, headers?: object, signal?: AbortSignal): Promise<PaperSessionSecret2Return>
  linkWallet(args: LinkWalletArgs, headers?: object, signal?: AbortSignal): Promise<LinkWalletReturn>
  getLinkedWallets(args: GetLinkedWalletsArgs, headers?: object, signal?: AbortSignal): Promise<GetLinkedWalletsReturn>
  removeLinkedWallet(args: RemoveLinkedWalletArgs, headers?: object, signal?: AbortSignal): Promise<RemoveLinkedWalletReturn>
  generateWaaSVerificationURL(
    args: GenerateWaaSVerificationURLArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GenerateWaaSVerificationURLReturn>
  validateWaaSVerificationNonce(
    args: ValidateWaaSVerificationNonceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ValidateWaaSVerificationNonceReturn>
  listAdoptedWallets(args: ListAdoptedWalletsArgs, headers?: object, signal?: AbortSignal): Promise<ListAdoptedWalletsReturn>
  getSwapPermit2Price(args: GetSwapPermit2PriceArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapPermit2PriceReturn>
  getSwapPermit2Prices(
    args: GetSwapPermit2PricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSwapPermit2PricesReturn>
  getSwapPermit2Quote(args: GetSwapPermit2QuoteArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapPermit2QuoteReturn>
  getSwapPrice(args: GetSwapPriceArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapPriceReturn>
  getSwapPrices(args: GetSwapPricesArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapPricesReturn>
  getSwapQuote(args: GetSwapQuoteArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapQuoteReturn>
  getSwapQuoteV2(args: GetSwapQuoteV2Args, headers?: object, signal?: AbortSignal): Promise<GetSwapQuoteV2Return>
  intentQuery(args: IntentQueryArgs, headers?: object, signal?: AbortSignal): Promise<IntentQueryReturn>
  listCurrencyGroups(headers?: object, signal?: AbortSignal): Promise<ListCurrencyGroupsReturn>
  addOffchainInventory(
    args: AddOffchainInventoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddOffchainInventoryReturn>
  getOffchainInventory(
    args: GetOffchainInventoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetOffchainInventoryReturn>
  listOffchainInventories(
    args: ListOffchainInventoriesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListOffchainInventoriesReturn>
  updateOffchainInventory(
    args: UpdateOffchainInventoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateOffchainInventoryReturn>
  deleteOffchainInventory(
    args: DeleteOffchainInventoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteOffchainInventoryReturn>
  requestOffchainPayment(
    args: RequestOffchainPaymentArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RequestOffchainPaymentReturn>
  listOffchainPayments(
    args: ListOffchainPaymentsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListOffchainPaymentsReturn>
  savePack(args: SavePackArgs, headers?: object, signal?: AbortSignal): Promise<SavePackReturn>
  getPack(args: GetPackArgs, headers?: object, signal?: AbortSignal): Promise<GetPackReturn>
  deletePack(args: DeletePackArgs, headers?: object, signal?: AbortSignal): Promise<DeletePackReturn>
  updatePackContent(args: UpdatePackContentArgs, headers?: object, signal?: AbortSignal): Promise<UpdatePackContentReturn>
}

export interface PingArgs {}

export interface PingReturn {
  status: boolean
}
export interface VersionArgs {}

export interface VersionReturn {
  version: Version
}
export interface RuntimeStatusArgs {}

export interface RuntimeStatusReturn {
  status: RuntimeStatus
}
export interface ClockArgs {}

export interface ClockReturn {
  serverTime: string
}
export interface GetSequenceContextArgs {}

export interface GetSequenceContextReturn {
  data: SequenceContext
}
export interface GetAuthTokenArgs {
  ewtString: string
  testnetMode?: boolean
}

export interface GetAuthTokenReturn {
  status: boolean
  jwtToken: string
  address: string
  user?: User
}
export interface GetAuthToken2Args {
  ewtString: string
  chainID: string
}

export interface GetAuthToken2Return {
  status: boolean
  jwtToken: string
  address: string
  user?: User
}
export interface SendPasswordlessLinkArgs {
  email: string
  redirectUri: string
  intent: string
}

export interface SendPasswordlessLinkReturn {
  status: boolean
}
export interface RegisterPublicKeyArgs {
  publicKey: PublicKey
}

export interface RegisterPublicKeyReturn {
  status: boolean
}
export interface GetPublicKeyArgs {
  id: string
}

export interface GetPublicKeyReturn {
  publicKey: PublicKey
}
export interface FriendListArgs {
  nickname?: string
  page?: Page
}

export interface FriendListReturn {
  page: Page
  friends: Array<Friend>
}
export interface GetFriendByAddressArgs {
  friendAddress: string
}

export interface GetFriendByAddressReturn {
  status: boolean
  friend: Friend
}
export interface SearchFriendsArgs {
  filterUsername: string
  page?: Page
}

export interface SearchFriendsReturn {
  friends: Array<Friend>
}
export interface AddFriendArgs {
  friendAddress: string
  optionalNickname?: string
}

export interface AddFriendReturn {
  status: boolean
  friend?: Friend
}
export interface UpdateFriendNicknameArgs {
  friendAddress: string
  nickname: string
}

export interface UpdateFriendNicknameReturn {
  status: boolean
  friend?: Friend
}
export interface RemoveFriendArgs {
  friendAddress: string
}

export interface RemoveFriendReturn {
  status: boolean
}
export interface ContractCallArgs {
  chainID: string
  contract: string
  inputExpr: string
  outputExpr: string
  args: Array<string>
}

export interface ContractCallReturn {
  returns: Array<string>
}
export interface DecodeContractCallArgs {
  callData: string
}

export interface DecodeContractCallReturn {
  call: ContractCall
}
export interface LookupContractCallSelectorsArgs {
  selectors: Array<string>
}

export interface LookupContractCallSelectorsReturn {
  signatures: Array<Array<string>>
}
export interface UserStorageFetchArgs {
  key: string
}

export interface UserStorageFetchReturn {
  object: any
}
export interface UserStorageSaveArgs {
  key: string
  object: any
}

export interface UserStorageSaveReturn {
  ok: boolean
}
export interface UserStorageDeleteArgs {
  key: string
}

export interface UserStorageDeleteReturn {
  ok: boolean
}
export interface UserStorageFetchAllArgs {
  keys?: Array<string>
}

export interface UserStorageFetchAllReturn {
  objects: { [key: string]: any }
}
export interface GetMoonpayLinkArgs {
  url: string
}

export interface GetMoonpayLinkReturn {
  signedUrl: string
}
export interface ResolveENSAddressArgs {
  ens: string
}

export interface ResolveENSAddressReturn {
  address: string
  ok: boolean
}
export interface IsValidSignatureArgs {
  chainId: string
  walletAddress: string
  digest: string
  signature: string
}

export interface IsValidSignatureReturn {
  isValid: boolean
}
export interface IsValidMessageSignatureArgs {
  chainId: string
  walletAddress: string
  message: string
  signature: string
}

export interface IsValidMessageSignatureReturn {
  isValid: boolean
}
export interface IsValidTypedDataSignatureArgs {
  chainId: string
  walletAddress: string
  typedData: any
  signature: string
}

export interface IsValidTypedDataSignatureReturn {
  isValid: boolean
}
export interface IsValidETHAuthProofArgs {
  chainId: string
  walletAddress: string
  ethAuthProofString: string
}

export interface IsValidETHAuthProofReturn {
  isValid: boolean
}
export interface SardineGetClientTokenArgs {}

export interface SardineGetClientTokenReturn {
  token: string
}
export interface SardineGetNFTCheckoutTokenArgs {
  params: SardineNFTCheckoutParams
}

export interface SardineGetNFTCheckoutTokenReturn {
  resp: SardineNFTCheckout
}
export interface SardineGetNFTCheckoutOrderStatusArgs {
  orderId: string
}

export interface SardineGetNFTCheckoutOrderStatusReturn {
  resp: SardineOrder
}
export interface SardineGetSupportedRegionsArgs {}

export interface SardineGetSupportedRegionsReturn {
  regions: Array<SardineRegion>
}
export interface SardineGetSupportedFiatCurrenciesArgs {}

export interface SardineGetSupportedFiatCurrenciesReturn {
  tokens: Array<SardineFiatCurrency>
}
export interface SardineGetSupportedTokensArgs {}

export interface SardineGetSupportedTokensReturn {
  tokens: Array<SardineSupportedToken>
}
export interface SardineGetSupportedTokenForSwapArgs {
  network: string
  tokenAddress: string
}

export interface SardineGetSupportedTokenForSwapReturn {
  token: SardineSupportedTokenForSwap
}
export interface SardineGetEnabledTokensArgs {}

export interface SardineGetEnabledTokensReturn {
  tokens: Array<SardineEnabledToken>
}
export interface SardineGetQuoteArgs {
  params: SardineGetQuoteParams
}

export interface SardineGetQuoteReturn {
  quote: SardineQuote
}
export interface GetSardineClientTokenArgs {}

export interface GetSardineClientTokenReturn {
  token: string
}
export interface GetSardineNFTCheckoutTokenArgs {
  params: SardineNFTCheckoutParams
}

export interface GetSardineNFTCheckoutTokenReturn {
  resp: SardineNFTCheckout
}
export interface GetSardineNFTCheckoutOrderStatusArgs {
  orderId: string
}

export interface GetSardineNFTCheckoutOrderStatusReturn {
  resp: SardineOrder
}
export interface TransakGetCountriesArgs {}

export interface TransakGetCountriesReturn {
  regions: Array<TransakCountry>
}
export interface TransakGetCryptoCurrenciesArgs {}

export interface TransakGetCryptoCurrenciesReturn {
  currencies: Array<TransakCryptoCurrency>
}
export interface TransakGetFiatCurrenciesArgs {}

export interface TransakGetFiatCurrenciesReturn {
  currencies: Array<TransakFiatCurrency>
}
export interface TransakGetPriceArgs {
  params: TransakGetPriceParams
}

export interface TransakGetPriceReturn {
  price: TransakPrice
}
export interface TransakGetSupportedNFTCheckoutChainsArgs {}

export interface TransakGetSupportedNFTCheckoutChainsReturn {
  chains: Array<TransakChain>
}
export interface GetCoinPricesArgs {
  tokens: Array<Token>
}

export interface GetCoinPricesReturn {
  tokenPrices: Array<TokenPrice>
}
export interface GetCollectiblePricesArgs {
  tokens: Array<Token>
}

export interface GetCollectiblePricesReturn {
  tokenPrices: Array<TokenPrice>
}
export interface GetExchangeRateArgs {
  toCurrency: string
}

export interface GetExchangeRateReturn {
  exchangeRate: ExchangeRate
}
export interface MemoryStoreArgs {
  key: string
  value: string
}

export interface MemoryStoreReturn {
  ok: boolean
}
export interface MemoryLoadArgs {
  key: string
}

export interface MemoryLoadReturn {
  value: string
}
export interface GetInviteInfoArgs {}

export interface GetInviteInfoReturn {
  inviteInfo: InviteInfo
}
export interface IsValidAccessCodeArgs {
  accessCode: string
}

export interface IsValidAccessCodeReturn {
  status: boolean
}
export interface InternalClaimAccessCodeArgs {
  address: string
  accessCode: string
}

export interface InternalClaimAccessCodeReturn {
  status: boolean
}
export interface BlockNumberAtTimeArgs {
  chainId: number
  timestamps: Array<number>
}

export interface BlockNumberAtTimeReturn {
  blocks: Array<number>
}
export interface PaperSessionSecretArgs {
  chainName: string
  contractAddress: string
  paramsJson: string
  contractType: string
}

export interface PaperSessionSecretReturn {
  secret: string
}
export interface PaperSessionSecret2Args {
  chainName: string
  contractAddress: string
  paramsJson: string
  abi: string
}

export interface PaperSessionSecret2Return {
  secret: string
}
export interface LinkWalletArgs {
  parentWalletAddress: string
  parentWalletMessage: string
  parentWalletSignature: string
  linkedWalletAddress: string
  linkedWalletMessage: string
  linkedWalletSignature: string
  signatureChainId: string
  linkedWalletType?: string
}

export interface LinkWalletReturn {
  status: boolean
}
export interface GetLinkedWalletsArgs {
  parentWalletAddress: string
  parentWalletMessage: string
  parentWalletSignature: string
  signatureChainId: string
}

export interface GetLinkedWalletsReturn {
  linkedWallets: Array<LinkedWallet>
}
export interface RemoveLinkedWalletArgs {
  parentWalletAddress: string
  parentWalletMessage: string
  parentWalletSignature: string
  linkedWalletAddress: string
  signatureChainId: string
}

export interface RemoveLinkedWalletReturn {
  status: boolean
}
export interface GenerateWaaSVerificationURLArgs {
  walletAddress: string
}

export interface GenerateWaaSVerificationURLReturn {
  nonce: string
  verificationURL: string
}
export interface ValidateWaaSVerificationNonceArgs {
  nonce: string
  signature: string
  sessionId: string
  chainId: string
}

export interface ValidateWaaSVerificationNonceReturn {
  walletAddress: string
}
export interface ListAdoptedWalletsArgs {
  page?: Page
}

export interface ListAdoptedWalletsReturn {
  page: Page
  wallets: Array<AdoptedChildWallet>
}
export interface GetSwapPermit2PriceArgs {
  buyCurrencyAddress: string
  sellCurrencyAddress: string
  buyAmount: string
  chainId: number
  slippagePercentage?: number
}

export interface GetSwapPermit2PriceReturn {
  swapPermit2Price: SwapPermit2Price
}
export interface GetSwapPermit2PricesArgs {
  userAddress: string
  buyCurrencyAddress: string
  buyAmount: string
  chainId: number
  slippagePercentage?: number
}

export interface GetSwapPermit2PricesReturn {
  swapPermit2Prices: Array<SwapPermit2Price>
}
export interface GetSwapPermit2QuoteArgs {
  userAddress: string
  buyCurrencyAddress: string
  sellCurrencyAddress: string
  buyAmount: string
  chainId: number
  includeApprove: boolean
  slippagePercentage?: number
}

export interface GetSwapPermit2QuoteReturn {
  swapPermit2Quote: SwapPermit2Quote
}
export interface GetSwapPriceArgs {
  buyCurrencyAddress: string
  sellCurrencyAddress: string
  buyAmount: string
  chainId: number
  slippagePercentage?: number
}

export interface GetSwapPriceReturn {
  swapPrice: SwapPrice
}
export interface GetSwapPricesArgs {
  userAddress: string
  buyCurrencyAddress: string
  buyAmount: string
  chainId: number
  slippagePercentage?: number
}

export interface GetSwapPricesReturn {
  swapPrices: Array<SwapPrice>
}
export interface GetSwapQuoteArgs {
  userAddress: string
  buyCurrencyAddress: string
  sellCurrencyAddress: string
  buyAmount: string
  chainId: number
  includeApprove: boolean
  slippagePercentage?: number
}

export interface GetSwapQuoteReturn {
  swapQuote: SwapQuote
}
export interface GetSwapQuoteV2Args {
  userAddress: string
  buyCurrencyAddress: string
  sellCurrencyAddress: string
  buyAmount: string
  chainId: number
  includeApprove: boolean
  slippagePercentage?: number
}

export interface GetSwapQuoteV2Return {
  swapQuote: SwapQuote
}
export interface IntentQueryArgs {
  wallet: string
  preconditions: Array<IntentPrecondition>
}

export interface IntentQueryReturn {
  solutions: Array<IntentSolution>
}
export interface ListCurrencyGroupsArgs {}

export interface ListCurrencyGroupsReturn {
  currencyGroups: Array<CurrencyGroup>
}
export interface AddOffchainInventoryArgs {
  inventory: OffchainInventory
}

export interface AddOffchainInventoryReturn {
  inventoryId: number
}
export interface GetOffchainInventoryArgs {
  inventoryId: number
}

export interface GetOffchainInventoryReturn {
  inventory: OffchainInventory
}
export interface ListOffchainInventoriesArgs {
  projectId: number
}

export interface ListOffchainInventoriesReturn {
  inventory: Array<OffchainInventory>
}
export interface UpdateOffchainInventoryArgs {
  inventory: OffchainInventory
}

export interface UpdateOffchainInventoryReturn {}
export interface DeleteOffchainInventoryArgs {
  inventoryId: number
}

export interface DeleteOffchainInventoryReturn {
  ok: boolean
}
export interface RequestOffchainPaymentArgs {
  inventoryId: number
  recipient: string
  chainId?: number
  tokenAddress?: string
}

export interface RequestOffchainPaymentReturn {
  payment: PaymentResponse
}
export interface ListOffchainPaymentsArgs {
  inventoryId: number
  page?: Page
}

export interface ListOffchainPaymentsReturn {
  page: Page
  payments: Array<OffchainPayment>
}
export interface SavePackArgs {
  pack: Pack
}

export interface SavePackReturn {
  merkleRoot: string
}
export interface GetPackArgs {
  contractAddress: string
  chainId: number
}

export interface GetPackReturn {
  pack: Pack
}
export interface DeletePackArgs {
  contractAddress: string
  chainId: number
}

export interface DeletePackReturn {
  status: boolean
}
export interface UpdatePackContentArgs {
  pack: Pack
}

export interface UpdatePackContentReturn {
  merkleRoot: string
}

//
// Client
//
export class API implements API {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/API/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname.replace(/\/*$/, '')
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  ping = (headers?: object, signal?: AbortSignal): Promise<PingReturn> => {
    return this.fetch(this.url('Ping'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  version = (headers?: object, signal?: AbortSignal): Promise<VersionReturn> => {
    return this.fetch(this.url('Version'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            version: <Version>_data.version
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  runtimeStatus = (headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn> => {
    return this.fetch(this.url('RuntimeStatus'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <RuntimeStatus>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  clock = (headers?: object, signal?: AbortSignal): Promise<ClockReturn> => {
    return this.fetch(this.url('Clock'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            serverTime: <string>_data.serverTime
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSequenceContext = (headers?: object, signal?: AbortSignal): Promise<GetSequenceContextReturn> => {
    return this.fetch(this.url('GetSequenceContext'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            data: <SequenceContext>_data.data
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getAuthToken = (args: GetAuthTokenArgs, headers?: object, signal?: AbortSignal): Promise<GetAuthTokenReturn> => {
    return this.fetch(this.url('GetAuthToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            jwtToken: <string>_data.jwtToken,
            address: <string>_data.address,
            user: <User>_data.user
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getAuthToken2 = (args: GetAuthToken2Args, headers?: object, signal?: AbortSignal): Promise<GetAuthToken2Return> => {
    return this.fetch(this.url('GetAuthToken2'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            jwtToken: <string>_data.jwtToken,
            address: <string>_data.address,
            user: <User>_data.user
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sendPasswordlessLink = (
    args: SendPasswordlessLinkArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SendPasswordlessLinkReturn> => {
    return this.fetch(this.url('SendPasswordlessLink'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  registerPublicKey = (args: RegisterPublicKeyArgs, headers?: object, signal?: AbortSignal): Promise<RegisterPublicKeyReturn> => {
    return this.fetch(this.url('RegisterPublicKey'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getPublicKey = (args: GetPublicKeyArgs, headers?: object, signal?: AbortSignal): Promise<GetPublicKeyReturn> => {
    return this.fetch(this.url('GetPublicKey'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            publicKey: <PublicKey>_data.publicKey
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  friendList = (args: FriendListArgs, headers?: object, signal?: AbortSignal): Promise<FriendListReturn> => {
    return this.fetch(this.url('FriendList'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            friends: <Array<Friend>>_data.friends
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getFriendByAddress = (
    args: GetFriendByAddressArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetFriendByAddressReturn> => {
    return this.fetch(this.url('GetFriendByAddress'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            friend: <Friend>_data.friend
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchFriends = (args: SearchFriendsArgs, headers?: object, signal?: AbortSignal): Promise<SearchFriendsReturn> => {
    return this.fetch(this.url('SearchFriends'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            friends: <Array<Friend>>_data.friends
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addFriend = (args: AddFriendArgs, headers?: object, signal?: AbortSignal): Promise<AddFriendReturn> => {
    return this.fetch(this.url('AddFriend'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            friend: <Friend>_data.friend
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateFriendNickname = (
    args: UpdateFriendNicknameArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateFriendNicknameReturn> => {
    return this.fetch(this.url('UpdateFriendNickname'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status,
            friend: <Friend>_data.friend
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeFriend = (args: RemoveFriendArgs, headers?: object, signal?: AbortSignal): Promise<RemoveFriendReturn> => {
    return this.fetch(this.url('RemoveFriend'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  contractCall = (args: ContractCallArgs, headers?: object, signal?: AbortSignal): Promise<ContractCallReturn> => {
    return this.fetch(this.url('ContractCall'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            returns: <Array<string>>_data.returns
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  decodeContractCall = (
    args: DecodeContractCallArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DecodeContractCallReturn> => {
    return this.fetch(this.url('DecodeContractCall'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            call: <ContractCall>_data.call
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  lookupContractCallSelectors = (
    args: LookupContractCallSelectorsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<LookupContractCallSelectorsReturn> => {
    return this.fetch(this.url('LookupContractCallSelectors'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            signatures: <Array<Array<string>>>_data.signatures
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  userStorageFetch = (args: UserStorageFetchArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageFetchReturn> => {
    return this.fetch(this.url('UserStorageFetch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            object: <any>_data.object
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  userStorageSave = (args: UserStorageSaveArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageSaveReturn> => {
    return this.fetch(this.url('UserStorageSave'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  userStorageDelete = (args: UserStorageDeleteArgs, headers?: object, signal?: AbortSignal): Promise<UserStorageDeleteReturn> => {
    return this.fetch(this.url('UserStorageDelete'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  userStorageFetchAll = (
    args: UserStorageFetchAllArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UserStorageFetchAllReturn> => {
    return this.fetch(this.url('UserStorageFetchAll'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            objects: <{ [key: string]: any }>_data.objects
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getMoonpayLink = (args: GetMoonpayLinkArgs, headers?: object, signal?: AbortSignal): Promise<GetMoonpayLinkReturn> => {
    return this.fetch(this.url('GetMoonpayLink'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            signedUrl: <string>_data.signedUrl
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  resolveENSAddress = (args: ResolveENSAddressArgs, headers?: object, signal?: AbortSignal): Promise<ResolveENSAddressReturn> => {
    return this.fetch(this.url('ResolveENSAddress'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            address: <string>_data.address,
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidSignature = (args: IsValidSignatureArgs, headers?: object, signal?: AbortSignal): Promise<IsValidSignatureReturn> => {
    return this.fetch(this.url('IsValidSignature'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isValid: <boolean>_data.isValid
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidMessageSignature = (
    args: IsValidMessageSignatureArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidMessageSignatureReturn> => {
    return this.fetch(this.url('IsValidMessageSignature'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isValid: <boolean>_data.isValid
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidTypedDataSignature = (
    args: IsValidTypedDataSignatureArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidTypedDataSignatureReturn> => {
    return this.fetch(this.url('IsValidTypedDataSignature'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isValid: <boolean>_data.isValid
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidETHAuthProof = (
    args: IsValidETHAuthProofArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsValidETHAuthProofReturn> => {
    return this.fetch(this.url('IsValidETHAuthProof'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            isValid: <boolean>_data.isValid
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetClientToken = (headers?: object, signal?: AbortSignal): Promise<SardineGetClientTokenReturn> => {
    return this.fetch(this.url('SardineGetClientToken'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <string>_data.token
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetNFTCheckoutToken = (
    args: SardineGetNFTCheckoutTokenArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SardineGetNFTCheckoutTokenReturn> => {
    return this.fetch(this.url('SardineGetNFTCheckoutToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            resp: <SardineNFTCheckout>_data.resp
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetNFTCheckoutOrderStatus = (
    args: SardineGetNFTCheckoutOrderStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SardineGetNFTCheckoutOrderStatusReturn> => {
    return this.fetch(this.url('SardineGetNFTCheckoutOrderStatus'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            resp: <SardineOrder>_data.resp
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetSupportedRegions = (headers?: object, signal?: AbortSignal): Promise<SardineGetSupportedRegionsReturn> => {
    return this.fetch(this.url('SardineGetSupportedRegions'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            regions: <Array<SardineRegion>>_data.regions
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetSupportedFiatCurrencies = (
    headers?: object,
    signal?: AbortSignal
  ): Promise<SardineGetSupportedFiatCurrenciesReturn> => {
    return this.fetch(this.url('SardineGetSupportedFiatCurrencies'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokens: <Array<SardineFiatCurrency>>_data.tokens
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetSupportedTokens = (headers?: object, signal?: AbortSignal): Promise<SardineGetSupportedTokensReturn> => {
    return this.fetch(this.url('SardineGetSupportedTokens'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokens: <Array<SardineSupportedToken>>_data.tokens
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetSupportedTokenForSwap = (
    args: SardineGetSupportedTokenForSwapArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SardineGetSupportedTokenForSwapReturn> => {
    return this.fetch(this.url('SardineGetSupportedTokenForSwap'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <SardineSupportedTokenForSwap>_data.token
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetEnabledTokens = (headers?: object, signal?: AbortSignal): Promise<SardineGetEnabledTokensReturn> => {
    return this.fetch(this.url('SardineGetEnabledTokens'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokens: <Array<SardineEnabledToken>>_data.tokens
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  sardineGetQuote = (args: SardineGetQuoteArgs, headers?: object, signal?: AbortSignal): Promise<SardineGetQuoteReturn> => {
    return this.fetch(this.url('SardineGetQuote'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            quote: <SardineQuote>_data.quote
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSardineClientToken = (headers?: object, signal?: AbortSignal): Promise<GetSardineClientTokenReturn> => {
    return this.fetch(this.url('GetSardineClientToken'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <string>_data.token
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSardineNFTCheckoutToken = (
    args: GetSardineNFTCheckoutTokenArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSardineNFTCheckoutTokenReturn> => {
    return this.fetch(this.url('GetSardineNFTCheckoutToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            resp: <SardineNFTCheckout>_data.resp
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSardineNFTCheckoutOrderStatus = (
    args: GetSardineNFTCheckoutOrderStatusArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSardineNFTCheckoutOrderStatusReturn> => {
    return this.fetch(this.url('GetSardineNFTCheckoutOrderStatus'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            resp: <SardineOrder>_data.resp
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  transakGetCountries = (headers?: object, signal?: AbortSignal): Promise<TransakGetCountriesReturn> => {
    return this.fetch(this.url('TransakGetCountries'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            regions: <Array<TransakCountry>>_data.regions
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  transakGetCryptoCurrencies = (headers?: object, signal?: AbortSignal): Promise<TransakGetCryptoCurrenciesReturn> => {
    return this.fetch(this.url('TransakGetCryptoCurrencies'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            currencies: <Array<TransakCryptoCurrency>>_data.currencies
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  transakGetFiatCurrencies = (headers?: object, signal?: AbortSignal): Promise<TransakGetFiatCurrenciesReturn> => {
    return this.fetch(this.url('TransakGetFiatCurrencies'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            currencies: <Array<TransakFiatCurrency>>_data.currencies
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  transakGetPrice = (args: TransakGetPriceArgs, headers?: object, signal?: AbortSignal): Promise<TransakGetPriceReturn> => {
    return this.fetch(this.url('TransakGetPrice'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            price: <TransakPrice>_data.price
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  transakGetSupportedNFTCheckoutChains = (
    headers?: object,
    signal?: AbortSignal
  ): Promise<TransakGetSupportedNFTCheckoutChainsReturn> => {
    return this.fetch(this.url('TransakGetSupportedNFTCheckoutChains'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            chains: <Array<TransakChain>>_data.chains
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getCoinPrices = (args: GetCoinPricesArgs, headers?: object, signal?: AbortSignal): Promise<GetCoinPricesReturn> => {
    return this.fetch(this.url('GetCoinPrices'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenPrices: <Array<TokenPrice>>_data.tokenPrices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getCollectiblePrices = (
    args: GetCollectiblePricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetCollectiblePricesReturn> => {
    return this.fetch(this.url('GetCollectiblePrices'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenPrices: <Array<TokenPrice>>_data.tokenPrices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getExchangeRate = (args: GetExchangeRateArgs, headers?: object, signal?: AbortSignal): Promise<GetExchangeRateReturn> => {
    return this.fetch(this.url('GetExchangeRate'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            exchangeRate: <ExchangeRate>_data.exchangeRate
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  memoryStore = (args: MemoryStoreArgs, headers?: object, signal?: AbortSignal): Promise<MemoryStoreReturn> => {
    return this.fetch(this.url('MemoryStore'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  memoryLoad = (args: MemoryLoadArgs, headers?: object, signal?: AbortSignal): Promise<MemoryLoadReturn> => {
    return this.fetch(this.url('MemoryLoad'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            value: <string>_data.value
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getInviteInfo = (headers?: object, signal?: AbortSignal): Promise<GetInviteInfoReturn> => {
    return this.fetch(this.url('GetInviteInfo'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            inviteInfo: <InviteInfo>_data.inviteInfo
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isValidAccessCode = (args: IsValidAccessCodeArgs, headers?: object, signal?: AbortSignal): Promise<IsValidAccessCodeReturn> => {
    return this.fetch(this.url('IsValidAccessCode'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  internalClaimAccessCode = (
    args: InternalClaimAccessCodeArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<InternalClaimAccessCodeReturn> => {
    return this.fetch(this.url('InternalClaimAccessCode'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  blockNumberAtTime = (args: BlockNumberAtTimeArgs, headers?: object, signal?: AbortSignal): Promise<BlockNumberAtTimeReturn> => {
    return this.fetch(this.url('BlockNumberAtTime'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            blocks: <Array<number>>_data.blocks
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  paperSessionSecret = (
    args: PaperSessionSecretArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<PaperSessionSecretReturn> => {
    return this.fetch(this.url('PaperSessionSecret'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            secret: <string>_data.secret
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  paperSessionSecret2 = (
    args: PaperSessionSecret2Args,
    headers?: object,
    signal?: AbortSignal
  ): Promise<PaperSessionSecret2Return> => {
    return this.fetch(this.url('PaperSessionSecret2'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            secret: <string>_data.secret
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  linkWallet = (args: LinkWalletArgs, headers?: object, signal?: AbortSignal): Promise<LinkWalletReturn> => {
    return this.fetch(this.url('LinkWallet'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getLinkedWallets = (args: GetLinkedWalletsArgs, headers?: object, signal?: AbortSignal): Promise<GetLinkedWalletsReturn> => {
    return this.fetch(this.url('GetLinkedWallets'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            linkedWallets: <Array<LinkedWallet>>_data.linkedWallets
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeLinkedWallet = (
    args: RemoveLinkedWalletArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveLinkedWalletReturn> => {
    return this.fetch(this.url('RemoveLinkedWallet'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  generateWaaSVerificationURL = (
    args: GenerateWaaSVerificationURLArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GenerateWaaSVerificationURLReturn> => {
    return this.fetch(this.url('GenerateWaaSVerificationURL'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            nonce: <string>_data.nonce,
            verificationURL: <string>_data.verificationURL
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  validateWaaSVerificationNonce = (
    args: ValidateWaaSVerificationNonceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ValidateWaaSVerificationNonceReturn> => {
    return this.fetch(this.url('ValidateWaaSVerificationNonce'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            walletAddress: <string>_data.walletAddress
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listAdoptedWallets = (
    args: ListAdoptedWalletsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListAdoptedWalletsReturn> => {
    return this.fetch(this.url('ListAdoptedWallets'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            wallets: <Array<AdoptedChildWallet>>_data.wallets
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSwapPermit2Price = (
    args: GetSwapPermit2PriceArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSwapPermit2PriceReturn> => {
    return this.fetch(this.url('GetSwapPermit2Price'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            swapPermit2Price: <SwapPermit2Price>_data.swapPermit2Price
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSwapPermit2Prices = (
    args: GetSwapPermit2PricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSwapPermit2PricesReturn> => {
    return this.fetch(this.url('GetSwapPermit2Prices'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            swapPermit2Prices: <Array<SwapPermit2Price>>_data.swapPermit2Prices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSwapPermit2Quote = (
    args: GetSwapPermit2QuoteArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetSwapPermit2QuoteReturn> => {
    return this.fetch(this.url('GetSwapPermit2Quote'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            swapPermit2Quote: <SwapPermit2Quote>_data.swapPermit2Quote
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSwapPrice = (args: GetSwapPriceArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapPriceReturn> => {
    return this.fetch(this.url('GetSwapPrice'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            swapPrice: <SwapPrice>_data.swapPrice
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSwapPrices = (args: GetSwapPricesArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapPricesReturn> => {
    return this.fetch(this.url('GetSwapPrices'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            swapPrices: <Array<SwapPrice>>_data.swapPrices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSwapQuote = (args: GetSwapQuoteArgs, headers?: object, signal?: AbortSignal): Promise<GetSwapQuoteReturn> => {
    return this.fetch(this.url('GetSwapQuote'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            swapQuote: <SwapQuote>_data.swapQuote
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getSwapQuoteV2 = (args: GetSwapQuoteV2Args, headers?: object, signal?: AbortSignal): Promise<GetSwapQuoteV2Return> => {
    return this.fetch(this.url('GetSwapQuoteV2'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            swapQuote: <SwapQuote>_data.swapQuote
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  intentQuery = (args: IntentQueryArgs, headers?: object, signal?: AbortSignal): Promise<IntentQueryReturn> => {
    return this.fetch(this.url('IntentQuery'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            solutions: <Array<IntentSolution>>_data.solutions
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listCurrencyGroups = (headers?: object, signal?: AbortSignal): Promise<ListCurrencyGroupsReturn> => {
    return this.fetch(this.url('ListCurrencyGroups'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            currencyGroups: <Array<CurrencyGroup>>_data.currencyGroups
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addOffchainInventory = (
    args: AddOffchainInventoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddOffchainInventoryReturn> => {
    return this.fetch(this.url('AddOffchainInventory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            inventoryId: <number>_data.inventoryId
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getOffchainInventory = (
    args: GetOffchainInventoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetOffchainInventoryReturn> => {
    return this.fetch(this.url('GetOffchainInventory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            inventory: <OffchainInventory>_data.inventory
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listOffchainInventories = (
    args: ListOffchainInventoriesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListOffchainInventoriesReturn> => {
    return this.fetch(this.url('ListOffchainInventories'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            inventory: <Array<OffchainInventory>>_data.inventory
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateOffchainInventory = (
    args: UpdateOffchainInventoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateOffchainInventoryReturn> => {
    return this.fetch(this.url('UpdateOffchainInventory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {}
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteOffchainInventory = (
    args: DeleteOffchainInventoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteOffchainInventoryReturn> => {
    return this.fetch(this.url('DeleteOffchainInventory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  requestOffchainPayment = (
    args: RequestOffchainPaymentArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RequestOffchainPaymentReturn> => {
    return this.fetch(this.url('RequestOffchainPayment'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            payment: <PaymentResponse>_data.payment
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listOffchainPayments = (
    args: ListOffchainPaymentsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListOffchainPaymentsReturn> => {
    return this.fetch(this.url('ListOffchainPayments'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            payments: <Array<OffchainPayment>>_data.payments
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  savePack = (args: SavePackArgs, headers?: object, signal?: AbortSignal): Promise<SavePackReturn> => {
    return this.fetch(this.url('SavePack'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            merkleRoot: <string>_data.merkleRoot
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getPack = (args: GetPackArgs, headers?: object, signal?: AbortSignal): Promise<GetPackReturn> => {
    return this.fetch(this.url('GetPack'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            pack: <Pack>_data.pack
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deletePack = (args: DeletePackArgs, headers?: object, signal?: AbortSignal): Promise<DeletePackReturn> => {
    return this.fetch(this.url('DeletePack'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updatePackContent = (args: UpdatePackContentArgs, headers?: object, signal?: AbortSignal): Promise<UpdatePackContentReturn> => {
    return this.fetch(this.url('UpdatePackContent'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            merkleRoot: <string>_data.merkleRoot
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}

const createHTTPRequest = (body: object = {}, headers: object = {}, signal: AbortSignal | null = null): object => {
  const reqHeaders: { [key: string]: string } = { ...headers, 'Content-Type': 'application/json' }
  reqHeaders[WebrpcHeader] = WebrpcHeaderValue

  return {
    method: 'POST',
    headers: reqHeaders,
    body: JSON.stringify(body || {}),
    signal
  }
}

const buildResponse = (res: Response): Promise<any> => {
  return res.text().then(text => {
    let data
    try {
      data = JSON.parse(text)
    } catch (error) {
      let message = ''
      if (error instanceof Error) {
        message = error.message
      }
      throw WebrpcBadResponseError.new({
        status: res.status,
        cause: `JSON.parse(): ${message}: response text: ${text}`
      })
    }
    if (!res.ok) {
      const code: number = typeof data.code === 'number' ? data.code : 0
      throw (webrpcErrorByCode[code] || WebrpcError).new(data)
    }
    return data
  })
}

//
// Errors
//

export class WebrpcError extends Error {
  name: string
  code: number
  message: string
  status: number
  cause?: string

  /** @deprecated Use message instead of msg. Deprecated in webrpc v0.11.0. */
  msg: string

  constructor(name: string, code: number, message: string, status: number, cause?: string) {
    super(message)
    this.name = name || 'WebrpcError'
    this.code = typeof code === 'number' ? code : 0
    this.message = message || `endpoint error ${this.code}`
    this.msg = this.message
    this.status = typeof status === 'number' ? status : 0
    this.cause = cause
    Object.setPrototypeOf(this, WebrpcError.prototype)
  }

  static new(payload: any): WebrpcError {
    return new this(payload.error, payload.code, payload.message || payload.msg, payload.status, payload.cause)
  }
}

// Webrpc errors

export class WebrpcEndpointError extends WebrpcError {
  constructor(
    name: string = 'WebrpcEndpoint',
    code: number = 0,
    message: string = `endpoint error`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcEndpointError.prototype)
  }
}

export class WebrpcRequestFailedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcRequestFailed',
    code: number = -1,
    message: string = `request failed`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcRequestFailedError.prototype)
  }
}

export class WebrpcBadRouteError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRoute',
    code: number = -2,
    message: string = `bad route`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRouteError.prototype)
  }
}

export class WebrpcBadMethodError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadMethod',
    code: number = -3,
    message: string = `bad method`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadMethodError.prototype)
  }
}

export class WebrpcBadRequestError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRequest',
    code: number = -4,
    message: string = `bad request`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRequestError.prototype)
  }
}

export class WebrpcBadResponseError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadResponse',
    code: number = -5,
    message: string = `bad response`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadResponseError.prototype)
  }
}

export class WebrpcServerPanicError extends WebrpcError {
  constructor(
    name: string = 'WebrpcServerPanic',
    code: number = -6,
    message: string = `server panic`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcServerPanicError.prototype)
  }
}

export class WebrpcInternalErrorError extends WebrpcError {
  constructor(
    name: string = 'WebrpcInternalError',
    code: number = -7,
    message: string = `internal error`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcInternalErrorError.prototype)
  }
}

export class WebrpcClientDisconnectedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcClientDisconnected',
    code: number = -8,
    message: string = `client disconnected`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcClientDisconnectedError.prototype)
  }
}

export class WebrpcStreamLostError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamLost',
    code: number = -9,
    message: string = `stream lost`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamLostError.prototype)
  }
}

export class WebrpcStreamFinishedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamFinished',
    code: number = -10,
    message: string = `stream finished`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamFinishedError.prototype)
  }
}

// Schema errors

export class UnauthorizedError extends WebrpcError {
  constructor(
    name: string = 'Unauthorized',
    code: number = 1000,
    message: string = `Unauthorized access`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnauthorizedError.prototype)
  }
}

export class PermissionDeniedError extends WebrpcError {
  constructor(
    name: string = 'PermissionDenied',
    code: number = 1001,
    message: string = `Permission denied`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, PermissionDeniedError.prototype)
  }
}

export class SessionExpiredError extends WebrpcError {
  constructor(
    name: string = 'SessionExpired',
    code: number = 1002,
    message: string = `Session expired`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, SessionExpiredError.prototype)
  }
}

export class MethodNotFoundError extends WebrpcError {
  constructor(
    name: string = 'MethodNotFound',
    code: number = 1003,
    message: string = `Method not found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, MethodNotFoundError.prototype)
  }
}

export class RequestConflictError extends WebrpcError {
  constructor(
    name: string = 'RequestConflict',
    code: number = 1004,
    message: string = `Conflict with target resource`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RequestConflictError.prototype)
  }
}

export class AbortedError extends WebrpcError {
  constructor(
    name: string = 'Aborted',
    code: number = 1005,
    message: string = `Request aborted`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AbortedError.prototype)
  }
}

export class GeoblockedError extends WebrpcError {
  constructor(
    name: string = 'Geoblocked',
    code: number = 1006,
    message: string = `Geoblocked region`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, GeoblockedError.prototype)
  }
}

export class RateLimitedError extends WebrpcError {
  constructor(
    name: string = 'RateLimited',
    code: number = 1007,
    message: string = `Rate-limited. Please slow down.`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RateLimitedError.prototype)
  }
}

export class ProjectNotFoundError extends WebrpcError {
  constructor(
    name: string = 'ProjectNotFound',
    code: number = 1008,
    message: string = `Project not found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, ProjectNotFoundError.prototype)
  }
}

export class AccessKeyNotFoundError extends WebrpcError {
  constructor(
    name: string = 'AccessKeyNotFound',
    code: number = 1101,
    message: string = `Access key not found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AccessKeyNotFoundError.prototype)
  }
}

export class AccessKeyMismatchError extends WebrpcError {
  constructor(
    name: string = 'AccessKeyMismatch',
    code: number = 1102,
    message: string = `Access key mismatch`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AccessKeyMismatchError.prototype)
  }
}

export class InvalidOriginError extends WebrpcError {
  constructor(
    name: string = 'InvalidOrigin',
    code: number = 1103,
    message: string = `Invalid origin for Access Key`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidOriginError.prototype)
  }
}

export class InvalidServiceError extends WebrpcError {
  constructor(
    name: string = 'InvalidService',
    code: number = 1104,
    message: string = `Service not enabled for Access key`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidServiceError.prototype)
  }
}

export class UnauthorizedUserError extends WebrpcError {
  constructor(
    name: string = 'UnauthorizedUser',
    code: number = 1105,
    message: string = `Unauthorized user`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnauthorizedUserError.prototype)
  }
}

export class QuotaExceededError extends WebrpcError {
  constructor(
    name: string = 'QuotaExceeded',
    code: number = 1200,
    message: string = `Quota request exceeded`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QuotaExceededError.prototype)
  }
}

export class QuotaRateLimitError extends WebrpcError {
  constructor(
    name: string = 'QuotaRateLimit',
    code: number = 1201,
    message: string = `Quota rate limit exceeded`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QuotaRateLimitError.prototype)
  }
}

export class NoDefaultKeyError extends WebrpcError {
  constructor(
    name: string = 'NoDefaultKey',
    code: number = 1300,
    message: string = `No default access key found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, NoDefaultKeyError.prototype)
  }
}

export class MaxAccessKeysError extends WebrpcError {
  constructor(
    name: string = 'MaxAccessKeys',
    code: number = 1301,
    message: string = `Access keys limit reached`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, MaxAccessKeysError.prototype)
  }
}

export class AtLeastOneKeyError extends WebrpcError {
  constructor(
    name: string = 'AtLeastOneKey',
    code: number = 1302,
    message: string = `You need at least one Access Key`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, AtLeastOneKeyError.prototype)
  }
}

export class TimeoutError extends WebrpcError {
  constructor(
    name: string = 'Timeout',
    code: number = 1900,
    message: string = `Request timed out`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, TimeoutError.prototype)
  }
}

export class InvalidArgumentError extends WebrpcError {
  constructor(
    name: string = 'InvalidArgument',
    code: number = 2000,
    message: string = `Invalid argument`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidArgumentError.prototype)
  }
}

export class UnavailableError extends WebrpcError {
  constructor(
    name: string = 'Unavailable',
    code: number = 2002,
    message: string = `Unavailable resource`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnavailableError.prototype)
  }
}

export class QueryFailedError extends WebrpcError {
  constructor(
    name: string = 'QueryFailed',
    code: number = 2003,
    message: string = `Query failed`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QueryFailedError.prototype)
  }
}

export class NotFoundError extends WebrpcError {
  constructor(
    name: string = 'NotFound',
    code: number = 3000,
    message: string = `Resource not found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}

export class UnsupportedNetworkError extends WebrpcError {
  constructor(
    name: string = 'UnsupportedNetwork',
    code: number = 3008,
    message: string = `Unsupported network`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnsupportedNetworkError.prototype)
  }
}

export enum errors {
  WebrpcEndpoint = 'WebrpcEndpoint',
  WebrpcRequestFailed = 'WebrpcRequestFailed',
  WebrpcBadRoute = 'WebrpcBadRoute',
  WebrpcBadMethod = 'WebrpcBadMethod',
  WebrpcBadRequest = 'WebrpcBadRequest',
  WebrpcBadResponse = 'WebrpcBadResponse',
  WebrpcServerPanic = 'WebrpcServerPanic',
  WebrpcInternalError = 'WebrpcInternalError',
  WebrpcClientDisconnected = 'WebrpcClientDisconnected',
  WebrpcStreamLost = 'WebrpcStreamLost',
  WebrpcStreamFinished = 'WebrpcStreamFinished',
  Unauthorized = 'Unauthorized',
  PermissionDenied = 'PermissionDenied',
  SessionExpired = 'SessionExpired',
  MethodNotFound = 'MethodNotFound',
  RequestConflict = 'RequestConflict',
  Aborted = 'Aborted',
  Geoblocked = 'Geoblocked',
  RateLimited = 'RateLimited',
  ProjectNotFound = 'ProjectNotFound',
  AccessKeyNotFound = 'AccessKeyNotFound',
  AccessKeyMismatch = 'AccessKeyMismatch',
  InvalidOrigin = 'InvalidOrigin',
  InvalidService = 'InvalidService',
  UnauthorizedUser = 'UnauthorizedUser',
  QuotaExceeded = 'QuotaExceeded',
  QuotaRateLimit = 'QuotaRateLimit',
  NoDefaultKey = 'NoDefaultKey',
  MaxAccessKeys = 'MaxAccessKeys',
  AtLeastOneKey = 'AtLeastOneKey',
  Timeout = 'Timeout',
  InvalidArgument = 'InvalidArgument',
  Unavailable = 'Unavailable',
  QueryFailed = 'QueryFailed',
  NotFound = 'NotFound',
  UnsupportedNetwork = 'UnsupportedNetwork'
}

export enum WebrpcErrorCodes {
  WebrpcEndpoint = 0,
  WebrpcRequestFailed = -1,
  WebrpcBadRoute = -2,
  WebrpcBadMethod = -3,
  WebrpcBadRequest = -4,
  WebrpcBadResponse = -5,
  WebrpcServerPanic = -6,
  WebrpcInternalError = -7,
  WebrpcClientDisconnected = -8,
  WebrpcStreamLost = -9,
  WebrpcStreamFinished = -10,
  Unauthorized = 1000,
  PermissionDenied = 1001,
  SessionExpired = 1002,
  MethodNotFound = 1003,
  RequestConflict = 1004,
  Aborted = 1005,
  Geoblocked = 1006,
  RateLimited = 1007,
  ProjectNotFound = 1008,
  AccessKeyNotFound = 1101,
  AccessKeyMismatch = 1102,
  InvalidOrigin = 1103,
  InvalidService = 1104,
  UnauthorizedUser = 1105,
  QuotaExceeded = 1200,
  QuotaRateLimit = 1201,
  NoDefaultKey = 1300,
  MaxAccessKeys = 1301,
  AtLeastOneKey = 1302,
  Timeout = 1900,
  InvalidArgument = 2000,
  Unavailable = 2002,
  QueryFailed = 2003,
  NotFound = 3000,
  UnsupportedNetwork = 3008
}

export const webrpcErrorByCode: { [code: number]: any } = {
  [0]: WebrpcEndpointError,
  [-1]: WebrpcRequestFailedError,
  [-2]: WebrpcBadRouteError,
  [-3]: WebrpcBadMethodError,
  [-4]: WebrpcBadRequestError,
  [-5]: WebrpcBadResponseError,
  [-6]: WebrpcServerPanicError,
  [-7]: WebrpcInternalErrorError,
  [-8]: WebrpcClientDisconnectedError,
  [-9]: WebrpcStreamLostError,
  [-10]: WebrpcStreamFinishedError,
  [1000]: UnauthorizedError,
  [1001]: PermissionDeniedError,
  [1002]: SessionExpiredError,
  [1003]: MethodNotFoundError,
  [1004]: RequestConflictError,
  [1005]: AbortedError,
  [1006]: GeoblockedError,
  [1007]: RateLimitedError,
  [1008]: ProjectNotFoundError,
  [1101]: AccessKeyNotFoundError,
  [1102]: AccessKeyMismatchError,
  [1103]: InvalidOriginError,
  [1104]: InvalidServiceError,
  [1105]: UnauthorizedUserError,
  [1200]: QuotaExceededError,
  [1201]: QuotaRateLimitError,
  [1300]: NoDefaultKeyError,
  [1301]: MaxAccessKeysError,
  [1302]: AtLeastOneKeyError,
  [1900]: TimeoutError,
  [2000]: InvalidArgumentError,
  [2002]: UnavailableError,
  [2003]: QueryFailedError,
  [3000]: NotFoundError,
  [3008]: UnsupportedNetworkError
}

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>
