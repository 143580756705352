/* eslint-disable */
// sequence-metadata v0.4.0 e3c00d11d576dac323d3413e444171880a3bc078
// --
// Code generated by webrpc-gen@v0.25.3 with typescript generator. DO NOT EDIT.
//
// webrpc-gen -schema=metadata.ridl -target=typescript -client -ignore=@deprecated -out=./clients/metadata.gen.ts

export const WebrpcHeader = 'Webrpc'

export const WebrpcHeaderValue = 'webrpc@v0.25.3;gen-typescript@v0.17.0;sequence-metadata@v0.4.0'

// WebRPC description and code-gen version
export const WebRPCVersion = 'v1'

// Schema version of your RIDL schema
export const WebRPCSchemaVersion = 'v0.4.0'

// Schema hash generated from your RIDL schema
export const WebRPCSchemaHash = 'e3c00d11d576dac323d3413e444171880a3bc078'

type WebrpcGenVersions = {
  webrpcGenVersion: string
  codeGenName: string
  codeGenVersion: string
  schemaName: string
  schemaVersion: string
}

export function VersionFromHeader(headers: Headers): WebrpcGenVersions {
  const headerValue = headers.get(WebrpcHeader)
  if (!headerValue) {
    return {
      webrpcGenVersion: '',
      codeGenName: '',
      codeGenVersion: '',
      schemaName: '',
      schemaVersion: ''
    }
  }

  return parseWebrpcGenVersions(headerValue)
}

function parseWebrpcGenVersions(header: string): WebrpcGenVersions {
  const versions = header.split(';')
  if (versions.length < 3) {
    return {
      webrpcGenVersion: '',
      codeGenName: '',
      codeGenVersion: '',
      schemaName: '',
      schemaVersion: ''
    }
  }

  const [_, webrpcGenVersion] = versions[0]!.split('@')
  const [codeGenName, codeGenVersion] = versions[1]!.split('@')
  const [schemaName, schemaVersion] = versions[2]!.split('@')

  return {
    webrpcGenVersion: webrpcGenVersion ?? '',
    codeGenName: codeGenName ?? '',
    codeGenVersion: codeGenVersion ?? '',
    schemaName: schemaName ?? '',
    schemaVersion: schemaVersion ?? ''
  }
}

//
// Types
//

export enum ContractType {
  UNKNOWN = 'UNKNOWN',
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
  ERC6909 = 'ERC6909',
  MISC = 'MISC'
}

export enum Source {
  UNKNOWN = 'UNKNOWN',
  FETCHER = 'FETCHER',
  FETCHER_OPENSEA_API = 'FETCHER_OPENSEA_API',
  FETCHER_ENS_API = 'FETCHER_ENS_API',
  FETCHER_ON_CHAIN_ERC20_INTERFACE = 'FETCHER_ON_CHAIN_ERC20_INTERFACE',
  FETCHER_ON_CHAIN_TOKEN_URI = 'FETCHER_ON_CHAIN_TOKEN_URI',
  FETCHER_ON_CHAIN_CONTRACT_URI = 'FETCHER_ON_CHAIN_CONTRACT_URI',
  FETCHER_TOKEN_DIRECTORY_ADMIN = 'FETCHER_TOKEN_DIRECTORY_ADMIN',
  TOKEN_DIRECTORY = 'TOKEN_DIRECTORY',
  TOKEN_DIRECTORY_PUBLIC_TOKEN_LIST = 'TOKEN_DIRECTORY_PUBLIC_TOKEN_LIST',
  TOKEN_DIRECTORY_3RD_PARTY = 'TOKEN_DIRECTORY_3RD_PARTY',
  TOKEN_DIRECTORY_SEQUENCE_GITHUB = 'TOKEN_DIRECTORY_SEQUENCE_GITHUB',
  TOKEN_DIRECTORY_SEQUENCE_BUILDER = 'TOKEN_DIRECTORY_SEQUENCE_BUILDER',
  SEQUENCE_BUILDER = 'SEQUENCE_BUILDER',
  SEQUENCE_BUILDER_DEPLOYED = 'SEQUENCE_BUILDER_DEPLOYED',
  SEQUENCE_BUILDER_COLLECTIONS = 'SEQUENCE_BUILDER_COLLECTIONS',
  SEQUENCE_BUILDER_ADMIN = 'SEQUENCE_BUILDER_ADMIN'
}

export enum ResourceStatus {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  REFRESHING = 'REFRESHING',
  AVAILABLE = 'AVAILABLE'
}

export enum PropertyType {
  INT = 'INT',
  STRING = 'STRING',
  ARRAY = 'ARRAY',
  GENERIC = 'GENERIC'
}

export enum SwapType {
  UNKNOWN = 'UNKNOWN',
  BUY = 'BUY',
  SELL = 'SELL'
}

export enum TaskStatus {
  QUEUED = 'QUEUED',
  PAUSED = 'PAUSED',
  FAILED = 'FAILED',
  DONE = 'DONE'
}

export interface Version {
  webrpcVersion: string
  schemaVersion: string
  schemaHash: string
  appVersion: string
}

export interface RuntimeStatus {
  healthOK: boolean
  startTime: string
  uptime: number
  uptimeString: string
  ver: string
  branch: string
  commitHash: string
  runnable: { [key: string]: RunnableStatus }
}

export interface RunnableStatus {
  running: boolean
  restarts: number
  startTime: string
  endTime?: string
  lastError: any
}

export interface ContractIndex {
  chainId: number
  address: string
  type: ContractType
  source: Source
  metadata: { [key: string]: any }
  contentHash: number
  deployed: boolean
  bytecodeHash: string
  notFound: boolean
  updatedAt: string
  queuedAt?: string
  status: ResourceStatus
}

export interface TokenIndex {
  chainId: number
  contractAddress: string
  tokenId: string
  source: Source
  metadata: { [key: string]: any }
  notFound?: boolean
  lastFetched?: string
  fetchCount?: number
  updatedAt: string
  queuedAt?: string
}

export interface ContractInfo {
  chainId: number
  address: string
  source: string
  name: string
  type: string
  symbol: string
  decimals?: number
  logoURI: string
  deployed: boolean
  bytecodeHash: string
  extensions: ContractInfoExtensions
  updatedAt: string
  queuedAt?: string
  status: ResourceStatus
}

export interface ContractInfoExtensions {
  link: string
  description: string
  categories: Array<string>
  ogImage: string
  ogName: string
  originChainId: number
  originAddress: string
  blacklist: boolean
  verified: boolean
  verifiedBy: string
  featured: boolean
  featureIndex: number
}

export interface ContractInfoOverride {
  name?: string
  type?: string
  symbol?: string
  decimals?: number
  logoURI?: string
  extensions: ContractInfoExtensionsOverride
}

export interface ContractInfoExtensionsOverride {
  link?: string
  description?: string
  categories?: Array<string>
  ogImage?: string
  ogName?: string
  originChainId?: number
  originAddress?: string
  blacklist?: boolean
  verified?: boolean
  verifiedBy?: string
  featureIndex?: number
}

export interface TokenMetadata {
  contractAddress?: string
  tokenId: string
  source: string
  name: string
  description?: string
  image?: string
  video?: string
  audio?: string
  properties?: { [key: string]: any }
  attributes: Array<{ [key: string]: any }>
  image_data?: string
  external_url?: string
  background_color?: string
  animation_url?: string
  decimals?: number
  updatedAt?: string
  assets?: Array<Asset>
  status: ResourceStatus
  queuedAt?: string
  lastFetched?: string
}

export interface PropertyFilter {
  name: string
  type: PropertyType
  min?: number
  max?: number
  values?: Array<any>
}

export interface Filter {
  text?: string
  properties?: Array<PropertyFilter>
}

export interface Collection {
  id: number
  projectId: number
  metadata: CollectionMetadata
  private: boolean
  revealKey?: string
  tokenCount?: number
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  baseURIs?: CollectionBaseURIs
  assets?: Array<Asset>
}

export interface CollectionMetadata {
  name: string
  description?: string
  image?: string
  external_link?: string
  properties?: { [key: string]: any }
  attributes?: Array<{ [key: string]: any }>
}

export interface CollectionBaseURIs {
  contractMetadataURI: string
  tokenMetadataURI: string
}

export interface ContractCollection {
  id: number
  chainId: number
  contractAddress: string
  collectionId: number
}

export interface Asset {
  id: number
  collectionId: number
  tokenId?: string
  url?: string
  metadataField: string
  name?: string
  filesize?: number
  mimeType?: string
  width?: number
  height?: number
  updatedAt?: string
}

export interface Token {
  collectionId: number
  tokenId: string
  metadata: TokenMetadata
  private: boolean
  updatedAt?: string
}

export interface GetNiftyswapUnitPricesRequest {
  swapType: SwapType
  ids: Array<string>
  amounts: Array<string>
}

export interface GetNiftyswapUnitPricesResponse {
  unitPrice: string
  unitAmount: string
  availableAmount: string
}

export interface Page {
  page?: number
  column?: string
  before?: any
  after?: any
  pageSize?: number
  more?: boolean
}

export interface Task {
  id: number
  queue: string
  status: TaskStatus
  try: number
  runAt?: string
  lastRanAt?: string
  createdAt?: string
  payload: Array<string>
  result: Array<string>
}

export interface Metadata {
  ping(headers?: object, signal?: AbortSignal): Promise<PingReturn>
  version(headers?: object, signal?: AbortSignal): Promise<VersionReturn>
  runtimeStatus(headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn>
  getTask(args: GetTaskArgs, headers?: object, signal?: AbortSignal): Promise<GetTaskReturn>
  getTaskStatus(args: GetTaskStatusArgs, headers?: object, signal?: AbortSignal): Promise<GetTaskStatusReturn>
  /**
   * Contract Info -- returns contract meta-info for contracts found in registered chain's token-lists
   */
  getContractInfo(args: GetContractInfoArgs, headers?: object, signal?: AbortSignal): Promise<GetContractInfoReturn>
  getContractInfoBatch(
    args: GetContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractInfoBatchReturn>
  /**
   * Find Contract Info across all chains token-lists. Similar to GetContractInfo above,
   * but it will traverse all chains and results from all.
   */
  findContractInfo(args: FindContractInfoArgs, headers?: object, signal?: AbortSignal): Promise<FindContractInfoReturn>
  /**
   * map of contractAddress :: []ContractInfo
   */
  findContractInfoBatch(
    args: FindContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<FindContractInfoBatchReturn>
  /**
   * Refresh Contract Info -- refresh contract meta-info
   */
  refreshContractInfo(args: RefreshContractInfoArgs, headers?: object, signal?: AbortSignal): Promise<RefreshContractInfoReturn>
  refreshContractInfoBatch(
    args: RefreshContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RefreshContractInfoBatchReturn>
  /**
   * Search for contract infos using a query string
   */
  searchContractsByQuery(
    args: SearchContractsByQueryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchContractsByQueryReturn>
  /**
   * GetTokenMetadata - fetch token metadata for a particular contract and respective tokenIDs
   */
  getTokenMetadata(args: GetTokenMetadataArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenMetadataReturn>
  /**
   * GetTokenMetadataBatch allows you to query the token metadata of a batch of contracts and respective tokenIDs
   * where map is contractAddress::[]tokenID => contractAddress::[]TokenMetadata
   *
   * Note, we limit each request to 50 contracts max and 50 tokens max per contract.
   */
  getTokenMetadataBatch(
    args: GetTokenMetadataBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenMetadataBatchReturn>
  /**
   * RefreshTokenMetadata allows you to refresh a contract metadata for contract-level and token-level metadata.
   */
  refreshTokenMetadata(
    args: RefreshTokenMetadataArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RefreshTokenMetadataReturn>
  /**
   * Search ERC721 & ERC1155 token metadata by query string 'q'
   */
  searchTokenMetadataByQuery(
    args: SearchTokenMetadataByQueryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchTokenMetadataByQueryReturn>
  /**
   * Search ERC721 & ERC1155 token metadata by filter object 'filter'
   * which allows to search by text or properties.
   */
  searchTokenMetadata(args: SearchTokenMetadataArgs, headers?: object, signal?: AbortSignal): Promise<SearchTokenMetadataReturn>
  /**
   * Search ERC721 & ERC1155 for token IDs by filter object 'filter'
   * which allows to search by text or properties.
   */
  searchTokenMetadataTokenIDs(
    args: SearchTokenMetadataTokenIDsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchTokenMetadataTokenIDsReturn>
  /**
   * Get token metadata property filters for a contract address
   */
  getTokenMetadataPropertyFilters(
    args: GetTokenMetadataPropertyFiltersArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenMetadataPropertyFiltersReturn>
  /**
   * Gets Token Directory supported networks
   */
  getTokenDirectoryNetworks(
    args: GetTokenDirectoryNetworksArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenDirectoryNetworksReturn>
  /**
   * Gets Token Directory entries
   */
  getTokenDirectory(args: GetTokenDirectoryArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenDirectoryReturn>
  /**
   * Search in Token Directory
   */
  searchTokenDirectory(
    args: SearchTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchTokenDirectoryReturn>
  /**
   * Niftyswap querying data
   */
  getNiftyswapTokenQuantity(
    args: GetNiftyswapTokenQuantityArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapTokenQuantityReturn>
  /**
   * map of tokenID :: quantity
   */
  getNiftyswapUnitPrices(
    args: GetNiftyswapUnitPricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapUnitPricesReturn>
  /**
   * map of tokenID :: price
   */
  getNiftyswapUnitPricesWithQuantities(
    args: GetNiftyswapUnitPricesWithQuantitiesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapUnitPricesWithQuantitiesReturn>
}

export interface PingArgs {}

export interface PingReturn {
  status: boolean
}
export interface VersionArgs {}

export interface VersionReturn {
  version: Version
}
export interface RuntimeStatusArgs {}

export interface RuntimeStatusReturn {
  status: RuntimeStatus
}
export interface GetTaskArgs {
  taskId: number
}

export interface GetTaskReturn {
  task: Task
}
export interface GetTaskStatusArgs {
  taskId: number
}

export interface GetTaskStatusReturn {
  status?: TaskStatus
}
export interface GetContractInfoArgs {
  chainID: string
  contractAddress: string
}

export interface GetContractInfoReturn {
  contractInfo: ContractInfo
  taskID?: number
}
export interface GetContractInfoBatchArgs {
  chainID: string
  contractAddresses: Array<string>
}

export interface GetContractInfoBatchReturn {
  contractInfoMap: { [key: string]: ContractInfo }
  taskID?: number
}
export interface FindContractInfoArgs {
  contractAddress: string
}

export interface FindContractInfoReturn {
  contractInfoList: Array<ContractInfo>
}
export interface FindContractInfoBatchArgs {
  contractAddresses: Array<string>
}

export interface FindContractInfoBatchReturn {
  contractInfoByChain: { [key: string]: Array<ContractInfo> }
}
export interface RefreshContractInfoArgs {
  chainID: string
  contractAddress: string
}

export interface RefreshContractInfoReturn {
  taskID?: number
}
export interface RefreshContractInfoBatchArgs {
  chainID: string
  contractAddresses: Array<string>
}

export interface RefreshContractInfoBatchReturn {
  taskID?: number
}
export interface SearchContractsByQueryArgs {
  q: string
  chainID?: string
  chainIDs?: Array<string>
  types?: Array<ContractType>
  page?: Page
}

export interface SearchContractsByQueryReturn {
  contractInfo: Array<ContractInfo>
  nextPage: Page
}
export interface GetTokenMetadataArgs {
  chainID: string
  contractAddress: string
  tokenIDs: Array<string>
}

export interface GetTokenMetadataReturn {
  tokenMetadata: Array<TokenMetadata>
  taskID?: number
}
export interface GetTokenMetadataBatchArgs {
  chainID: string
  contractTokenMap: { [key: string]: Array<string> }
}

export interface GetTokenMetadataBatchReturn {
  contractTokenMetadata: { [key: string]: Array<TokenMetadata> }
  taskID?: number
}
export interface RefreshTokenMetadataArgs {
  chainID: string
  contractAddress: string
  tokenIDs?: Array<string>
  refreshAll?: boolean
}

export interface RefreshTokenMetadataReturn {
  taskID: number
}
export interface SearchTokenMetadataByQueryArgs {
  q: string
  chainID?: string
  contractAddress?: string
  page?: Page
}

export interface SearchTokenMetadataByQueryReturn {
  tokenMetadata: Array<TokenMetadata>
  nextPage: Page
}
export interface SearchTokenMetadataArgs {
  chainID: string
  contractAddress: string
  filter: Filter
  page?: Page
}

export interface SearchTokenMetadataReturn {
  page: Page
  tokenMetadata: Array<TokenMetadata>
}
export interface SearchTokenMetadataTokenIDsArgs {
  chainID: string
  contractAddress: string
  filter: Filter
  page?: Page
}

export interface SearchTokenMetadataTokenIDsReturn {
  page: Page
  tokenIDs: Array<string>
}
export interface GetTokenMetadataPropertyFiltersArgs {
  chainID: string
  contractAddress: string
  excludeProperties: Array<string>
  excludePropertyValues?: boolean
}

export interface GetTokenMetadataPropertyFiltersReturn {
  filters: Array<PropertyFilter>
}
export interface GetTokenDirectoryNetworksArgs {
  includeTestnets?: boolean
  onlyFeatured?: boolean
}

export interface GetTokenDirectoryNetworksReturn {
  chainIDs: Array<number>
  networks: Array<string>
}
export interface GetTokenDirectoryArgs {
  chainID?: string
  includeTestnets?: boolean
  onlyFeatured?: boolean
  page?: Page
}

export interface GetTokenDirectoryReturn {
  contracts: Array<ContractInfo>
  page: Page
}
export interface SearchTokenDirectoryArgs {
  query: string
  chainID?: number
  includeTestnets?: boolean
  onlyFeatured?: boolean
  page?: Page
}

export interface SearchTokenDirectoryReturn {
  contracts: Array<ContractInfo>
  page: Page
}
export interface GetNiftyswapTokenQuantityArgs {
  chainID: string
  contractAddress: string
  tokenIDs: Array<string>
}

export interface GetNiftyswapTokenQuantityReturn {
  quantity: { [key: string]: string }
}
export interface GetNiftyswapUnitPricesArgs {
  chainID: string
  contractAddress: string
  req: GetNiftyswapUnitPricesRequest
  fresh: boolean
}

export interface GetNiftyswapUnitPricesReturn {
  prices: { [key: string]: string }
}
export interface GetNiftyswapUnitPricesWithQuantitiesArgs {
  chainID: string
  contractAddress: string
  req: GetNiftyswapUnitPricesRequest
  fresh: boolean
}

export interface GetNiftyswapUnitPricesWithQuantitiesReturn {
  prices: { [key: string]: GetNiftyswapUnitPricesResponse }
}

export interface Collections {
  createCollection(args: CreateCollectionArgs, headers?: object, signal?: AbortSignal): Promise<CreateCollectionReturn>
  getCollection(args: GetCollectionArgs, headers?: object, signal?: AbortSignal): Promise<GetCollectionReturn>
  listCollections(args: ListCollectionsArgs, headers?: object, signal?: AbortSignal): Promise<ListCollectionsReturn>
  updateCollection(args: UpdateCollectionArgs, headers?: object, signal?: AbortSignal): Promise<UpdateCollectionReturn>
  deleteCollection(args: DeleteCollectionArgs, headers?: object, signal?: AbortSignal): Promise<DeleteCollectionReturn>
  publishCollection(args: PublishCollectionArgs, headers?: object, signal?: AbortSignal): Promise<PublishCollectionReturn>
  unpublishCollection(args: UnpublishCollectionArgs, headers?: object, signal?: AbortSignal): Promise<UnpublishCollectionReturn>
  createContractCollection(
    args: CreateContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<CreateContractCollectionReturn>
  getContractCollection(
    args: GetContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractCollectionReturn>
  listContractCollections(
    args: ListContractCollectionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListContractCollectionsReturn>
  updateContractCollection(
    args: UpdateContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateContractCollectionReturn>
  deleteContractCollection(
    args: DeleteContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteContractCollectionReturn>
  createToken(args: CreateTokenArgs, headers?: object, signal?: AbortSignal): Promise<CreateTokenReturn>
  getToken(args: GetTokenArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenReturn>
  listTokens(args: ListTokensArgs, headers?: object, signal?: AbortSignal): Promise<ListTokensReturn>
  updateToken(args: UpdateTokenArgs, headers?: object, signal?: AbortSignal): Promise<UpdateTokenReturn>
  deleteToken(args: DeleteTokenArgs, headers?: object, signal?: AbortSignal): Promise<DeleteTokenReturn>
  createAsset(args: CreateAssetArgs, headers?: object, signal?: AbortSignal): Promise<CreateAssetReturn>
  getAsset(args: GetAssetArgs, headers?: object, signal?: AbortSignal): Promise<GetAssetReturn>
  updateAsset(args: UpdateAssetArgs, headers?: object, signal?: AbortSignal): Promise<UpdateAssetReturn>
  deleteAsset(args: DeleteAssetArgs, headers?: object, signal?: AbortSignal): Promise<DeleteAssetReturn>
}

export interface CreateCollectionArgs {
  projectId?: number
  collection: Collection
}

export interface CreateCollectionReturn {
  collection: Collection
}
export interface GetCollectionArgs {
  projectId?: number
  collectionId: number
}

export interface GetCollectionReturn {
  collection: Collection
}
export interface ListCollectionsArgs {
  projectId?: number
  page?: Page
}

export interface ListCollectionsReturn {
  page: Page
  collections: Array<Collection>
}
export interface UpdateCollectionArgs {
  projectId?: number
  collection: Collection
}

export interface UpdateCollectionReturn {
  collection: Collection
}
export interface DeleteCollectionArgs {
  projectId?: number
  collectionId: number
}

export interface DeleteCollectionReturn {
  status: boolean
}
export interface PublishCollectionArgs {
  projectId?: number
  collectionId: number
  recursive?: boolean
}

export interface PublishCollectionReturn {
  collection: Collection
}
export interface UnpublishCollectionArgs {
  projectId?: number
  collectionId: number
}

export interface UnpublishCollectionReturn {
  collection: Collection
}
export interface CreateContractCollectionArgs {
  projectId: number
  contractCollection: ContractCollection
}

export interface CreateContractCollectionReturn {
  contractCollection: ContractCollection
}
export interface GetContractCollectionArgs {
  projectId: number
  chainId: number
  contractAddress: string
}

export interface GetContractCollectionReturn {
  contractCollection: ContractCollection
}
export interface ListContractCollectionsArgs {
  projectId: number
  collectionId?: number
  page?: Page
}

export interface ListContractCollectionsReturn {
  contractCollections: Array<ContractCollection>
  collections: Array<Collection>
  page: Page
}
export interface UpdateContractCollectionArgs {
  projectId: number
  contractCollection: ContractCollection
}

export interface UpdateContractCollectionReturn {
  ok: boolean
}
export interface DeleteContractCollectionArgs {
  projectId: number
  chainId: number
  contractAddress: string
}

export interface DeleteContractCollectionReturn {
  ok: boolean
}
export interface CreateTokenArgs {
  projectId?: number
  collectionId: number
  token: TokenMetadata
  private?: boolean
}

export interface CreateTokenReturn {
  token: TokenMetadata
  assets: Array<Asset>
}
export interface GetTokenArgs {
  projectId?: number
  collectionId: number
  tokenId: string
}

export interface GetTokenReturn {
  token: TokenMetadata
  assets: Array<Asset>
}
export interface ListTokensArgs {
  projectId?: number
  collectionId: number
  page?: Page
}

export interface ListTokensReturn {
  page: Page
  tokens: Array<TokenMetadata>
}
export interface UpdateTokenArgs {
  projectId?: number
  collectionId: number
  tokenId: string
  token: TokenMetadata
  private?: boolean
}

export interface UpdateTokenReturn {
  token: TokenMetadata
}
export interface DeleteTokenArgs {
  projectId?: number
  collectionId: number
  tokenId: string
}

export interface DeleteTokenReturn {
  status: boolean
}
export interface CreateAssetArgs {
  projectId?: number
  asset: Asset
}

export interface CreateAssetReturn {
  asset: Asset
}
export interface GetAssetArgs {
  projectId?: number
  assetId: number
}

export interface GetAssetReturn {
  asset: Asset
}
export interface UpdateAssetArgs {
  projectId?: number
  asset: Asset
}

export interface UpdateAssetReturn {
  asset: Asset
}
export interface DeleteAssetArgs {
  projectId?: number
  assetId: number
}

export interface DeleteAssetReturn {
  status: boolean
}

export interface Admin {
  /**
   * Contract Info Overrides
   */
  getContractInfoOverride(
    args: GetContractInfoOverrideArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractInfoOverrideReturn>
  getContractInfoOverrides(
    args: GetContractInfoOverridesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractInfoOverridesReturn>
  addContractInfoOverride(
    args: AddContractInfoOverrideArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddContractInfoOverrideReturn>
  updateContractInfoOverride(
    args: UpdateContractInfoOverrideArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateContractInfoOverrideReturn>
  removeContractInfoOverride(
    args: RemoveContractInfoOverrideArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveContractInfoOverrideReturn>
  /**
   * Token Directory
   */
  isInTokenDirectory(args: IsInTokenDirectoryArgs, headers?: object, signal?: AbortSignal): Promise<IsInTokenDirectoryReturn>
  setTokenDirectoryFeatureIndex(
    args: SetTokenDirectoryFeatureIndexArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SetTokenDirectoryFeatureIndexReturn>
  addContractToTokenDirectory(
    args: AddContractToTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddContractToTokenDirectoryReturn>
  removeContractFromTokenDirectory(
    args: RemoveContractFromTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveContractFromTokenDirectoryReturn>
  refreshTokenDirectory(headers?: object, signal?: AbortSignal): Promise<RefreshTokenDirectoryReturn>
}

export interface GetContractInfoOverrideArgs {
  chainID: string
  contractAddress: string
}

export interface GetContractInfoOverrideReturn {
  contractInfoOverride: ContractInfoOverride
}
export interface GetContractInfoOverridesArgs {
  chainID?: string
  page?: Page
}

export interface GetContractInfoOverridesReturn {
  contractInfoOverrides: Array<ContractInfoOverride>
  page: Page
}
export interface AddContractInfoOverrideArgs {
  chainID: string
  contractAddress: string
  contractInfoOverride: ContractInfoOverride
}

export interface AddContractInfoOverrideReturn {
  ok: boolean
}
export interface UpdateContractInfoOverrideArgs {
  chainID: string
  contractAddress: string
  contractInfoOverride: ContractInfoOverride
}

export interface UpdateContractInfoOverrideReturn {
  ok: boolean
}
export interface RemoveContractInfoOverrideArgs {
  chainID: string
  contractAddress: string
}

export interface RemoveContractInfoOverrideReturn {
  ok: boolean
}
export interface IsInTokenDirectoryArgs {
  chainID: string
  contractAddress: string
}

export interface IsInTokenDirectoryReturn {
  ok: boolean
  featureIndex: number
}
export interface SetTokenDirectoryFeatureIndexArgs {
  chainID: string
  contractAddress: string
  featureIndex: number
}

export interface SetTokenDirectoryFeatureIndexReturn {
  ok: boolean
}
export interface AddContractToTokenDirectoryArgs {
  chainID: string
  contractAddress: string
}

export interface AddContractToTokenDirectoryReturn {
  ok: boolean
}
export interface RemoveContractFromTokenDirectoryArgs {
  chainID: string
  contractAddress: string
}

export interface RemoveContractFromTokenDirectoryReturn {
  ok: boolean
}
export interface RefreshTokenDirectoryArgs {}

export interface RefreshTokenDirectoryReturn {
  taskID: number
}

//
// Client
//
export class Metadata implements Metadata {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/Metadata/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname.replace(/\/*$/, '')
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  ping = (headers?: object, signal?: AbortSignal): Promise<PingReturn> => {
    return this.fetch(this.url('Ping'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  version = (headers?: object, signal?: AbortSignal): Promise<VersionReturn> => {
    return this.fetch(this.url('Version'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            version: <Version>_data.version
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  runtimeStatus = (headers?: object, signal?: AbortSignal): Promise<RuntimeStatusReturn> => {
    return this.fetch(this.url('RuntimeStatus'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <RuntimeStatus>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTask = (args: GetTaskArgs, headers?: object, signal?: AbortSignal): Promise<GetTaskReturn> => {
    return this.fetch(this.url('GetTask'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            task: <Task>_data.task
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTaskStatus = (args: GetTaskStatusArgs, headers?: object, signal?: AbortSignal): Promise<GetTaskStatusReturn> => {
    return this.fetch(this.url('GetTaskStatus'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <TaskStatus>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getContractInfo = (args: GetContractInfoArgs, headers?: object, signal?: AbortSignal): Promise<GetContractInfoReturn> => {
    return this.fetch(this.url('GetContractInfo'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfo: <ContractInfo>_data.contractInfo,
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getContractInfoBatch = (
    args: GetContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractInfoBatchReturn> => {
    return this.fetch(this.url('GetContractInfoBatch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfoMap: <{ [key: string]: ContractInfo }>_data.contractInfoMap,
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  findContractInfo = (args: FindContractInfoArgs, headers?: object, signal?: AbortSignal): Promise<FindContractInfoReturn> => {
    return this.fetch(this.url('FindContractInfo'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfoList: <Array<ContractInfo>>_data.contractInfoList
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  findContractInfoBatch = (
    args: FindContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<FindContractInfoBatchReturn> => {
    return this.fetch(this.url('FindContractInfoBatch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfoByChain: <{ [key: string]: Array<ContractInfo> }>_data.contractInfoByChain
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  refreshContractInfo = (
    args: RefreshContractInfoArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RefreshContractInfoReturn> => {
    return this.fetch(this.url('RefreshContractInfo'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  refreshContractInfoBatch = (
    args: RefreshContractInfoBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RefreshContractInfoBatchReturn> => {
    return this.fetch(this.url('RefreshContractInfoBatch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchContractsByQuery = (
    args: SearchContractsByQueryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchContractsByQueryReturn> => {
    return this.fetch(this.url('SearchContractsByQuery'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfo: <Array<ContractInfo>>_data.contractInfo,
            nextPage: <Page>_data.nextPage
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenMetadata = (args: GetTokenMetadataArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenMetadataReturn> => {
    return this.fetch(this.url('GetTokenMetadata'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenMetadata: <Array<TokenMetadata>>_data.tokenMetadata,
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenMetadataBatch = (
    args: GetTokenMetadataBatchArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenMetadataBatchReturn> => {
    return this.fetch(this.url('GetTokenMetadataBatch'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractTokenMetadata: <{ [key: string]: Array<TokenMetadata> }>_data.contractTokenMetadata,
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  refreshTokenMetadata = (
    args: RefreshTokenMetadataArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RefreshTokenMetadataReturn> => {
    return this.fetch(this.url('RefreshTokenMetadata'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchTokenMetadataByQuery = (
    args: SearchTokenMetadataByQueryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchTokenMetadataByQueryReturn> => {
    return this.fetch(this.url('SearchTokenMetadataByQuery'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            tokenMetadata: <Array<TokenMetadata>>_data.tokenMetadata,
            nextPage: <Page>_data.nextPage
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchTokenMetadata = (
    args: SearchTokenMetadataArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchTokenMetadataReturn> => {
    return this.fetch(this.url('SearchTokenMetadata'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            tokenMetadata: <Array<TokenMetadata>>_data.tokenMetadata
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchTokenMetadataTokenIDs = (
    args: SearchTokenMetadataTokenIDsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchTokenMetadataTokenIDsReturn> => {
    return this.fetch(this.url('SearchTokenMetadataTokenIDs'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            tokenIDs: <Array<string>>_data.tokenIDs
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenMetadataPropertyFilters = (
    args: GetTokenMetadataPropertyFiltersArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenMetadataPropertyFiltersReturn> => {
    return this.fetch(this.url('GetTokenMetadataPropertyFilters'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            filters: <Array<PropertyFilter>>_data.filters
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenDirectoryNetworks = (
    args: GetTokenDirectoryNetworksArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetTokenDirectoryNetworksReturn> => {
    return this.fetch(this.url('GetTokenDirectoryNetworks'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            chainIDs: <Array<number>>_data.chainIDs,
            networks: <Array<string>>_data.networks
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getTokenDirectory = (args: GetTokenDirectoryArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenDirectoryReturn> => {
    return this.fetch(this.url('GetTokenDirectory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contracts: <Array<ContractInfo>>_data.contracts,
            page: <Page>_data.page
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  searchTokenDirectory = (
    args: SearchTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SearchTokenDirectoryReturn> => {
    return this.fetch(this.url('SearchTokenDirectory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contracts: <Array<ContractInfo>>_data.contracts,
            page: <Page>_data.page
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getNiftyswapTokenQuantity = (
    args: GetNiftyswapTokenQuantityArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapTokenQuantityReturn> => {
    return this.fetch(this.url('GetNiftyswapTokenQuantity'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            quantity: <{ [key: string]: string }>_data.quantity
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getNiftyswapUnitPrices = (
    args: GetNiftyswapUnitPricesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapUnitPricesReturn> => {
    return this.fetch(this.url('GetNiftyswapUnitPrices'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            prices: <{ [key: string]: string }>_data.prices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getNiftyswapUnitPricesWithQuantities = (
    args: GetNiftyswapUnitPricesWithQuantitiesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetNiftyswapUnitPricesWithQuantitiesReturn> => {
    return this.fetch(this.url('GetNiftyswapUnitPricesWithQuantities'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            prices: <{ [key: string]: GetNiftyswapUnitPricesResponse }>_data.prices
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}
export class Collections implements Collections {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/Collections/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname.replace(/\/*$/, '')
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  createCollection = (args: CreateCollectionArgs, headers?: object, signal?: AbortSignal): Promise<CreateCollectionReturn> => {
    return this.fetch(this.url('CreateCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getCollection = (args: GetCollectionArgs, headers?: object, signal?: AbortSignal): Promise<GetCollectionReturn> => {
    return this.fetch(this.url('GetCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listCollections = (args: ListCollectionsArgs, headers?: object, signal?: AbortSignal): Promise<ListCollectionsReturn> => {
    return this.fetch(this.url('ListCollections'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            collections: <Array<Collection>>_data.collections
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateCollection = (args: UpdateCollectionArgs, headers?: object, signal?: AbortSignal): Promise<UpdateCollectionReturn> => {
    return this.fetch(this.url('UpdateCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteCollection = (args: DeleteCollectionArgs, headers?: object, signal?: AbortSignal): Promise<DeleteCollectionReturn> => {
    return this.fetch(this.url('DeleteCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  publishCollection = (args: PublishCollectionArgs, headers?: object, signal?: AbortSignal): Promise<PublishCollectionReturn> => {
    return this.fetch(this.url('PublishCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  unpublishCollection = (
    args: UnpublishCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UnpublishCollectionReturn> => {
    return this.fetch(this.url('UnpublishCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            collection: <Collection>_data.collection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  createContractCollection = (
    args: CreateContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<CreateContractCollectionReturn> => {
    return this.fetch(this.url('CreateContractCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractCollection: <ContractCollection>_data.contractCollection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getContractCollection = (
    args: GetContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractCollectionReturn> => {
    return this.fetch(this.url('GetContractCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractCollection: <ContractCollection>_data.contractCollection
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listContractCollections = (
    args: ListContractCollectionsArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<ListContractCollectionsReturn> => {
    return this.fetch(this.url('ListContractCollections'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractCollections: <Array<ContractCollection>>_data.contractCollections,
            collections: <Array<Collection>>_data.collections,
            page: <Page>_data.page
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateContractCollection = (
    args: UpdateContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateContractCollectionReturn> => {
    return this.fetch(this.url('UpdateContractCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteContractCollection = (
    args: DeleteContractCollectionArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<DeleteContractCollectionReturn> => {
    return this.fetch(this.url('DeleteContractCollection'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  createToken = (args: CreateTokenArgs, headers?: object, signal?: AbortSignal): Promise<CreateTokenReturn> => {
    return this.fetch(this.url('CreateToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <TokenMetadata>_data.token,
            assets: <Array<Asset>>_data.assets
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getToken = (args: GetTokenArgs, headers?: object, signal?: AbortSignal): Promise<GetTokenReturn> => {
    return this.fetch(this.url('GetToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <TokenMetadata>_data.token,
            assets: <Array<Asset>>_data.assets
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  listTokens = (args: ListTokensArgs, headers?: object, signal?: AbortSignal): Promise<ListTokensReturn> => {
    return this.fetch(this.url('ListTokens'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            page: <Page>_data.page,
            tokens: <Array<TokenMetadata>>_data.tokens
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateToken = (args: UpdateTokenArgs, headers?: object, signal?: AbortSignal): Promise<UpdateTokenReturn> => {
    return this.fetch(this.url('UpdateToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            token: <TokenMetadata>_data.token
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteToken = (args: DeleteTokenArgs, headers?: object, signal?: AbortSignal): Promise<DeleteTokenReturn> => {
    return this.fetch(this.url('DeleteToken'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  createAsset = (args: CreateAssetArgs, headers?: object, signal?: AbortSignal): Promise<CreateAssetReturn> => {
    return this.fetch(this.url('CreateAsset'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            asset: <Asset>_data.asset
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getAsset = (args: GetAssetArgs, headers?: object, signal?: AbortSignal): Promise<GetAssetReturn> => {
    return this.fetch(this.url('GetAsset'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            asset: <Asset>_data.asset
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateAsset = (args: UpdateAssetArgs, headers?: object, signal?: AbortSignal): Promise<UpdateAssetReturn> => {
    return this.fetch(this.url('UpdateAsset'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            asset: <Asset>_data.asset
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  deleteAsset = (args: DeleteAssetArgs, headers?: object, signal?: AbortSignal): Promise<DeleteAssetReturn> => {
    return this.fetch(this.url('DeleteAsset'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            status: <boolean>_data.status
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}
export class Admin implements Admin {
  protected hostname: string
  protected fetch: Fetch
  protected path = '/rpc/Admin/'

  constructor(hostname: string, fetch: Fetch) {
    this.hostname = hostname.replace(/\/*$/, '')
    this.fetch = (input: RequestInfo, init?: RequestInit) => fetch(input, init)
  }

  private url(name: string): string {
    return this.hostname + this.path + name
  }

  getContractInfoOverride = (
    args: GetContractInfoOverrideArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractInfoOverrideReturn> => {
    return this.fetch(this.url('GetContractInfoOverride'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfoOverride: <ContractInfoOverride>_data.contractInfoOverride
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  getContractInfoOverrides = (
    args: GetContractInfoOverridesArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<GetContractInfoOverridesReturn> => {
    return this.fetch(this.url('GetContractInfoOverrides'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            contractInfoOverrides: <Array<ContractInfoOverride>>_data.contractInfoOverrides,
            page: <Page>_data.page
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addContractInfoOverride = (
    args: AddContractInfoOverrideArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddContractInfoOverrideReturn> => {
    return this.fetch(this.url('AddContractInfoOverride'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  updateContractInfoOverride = (
    args: UpdateContractInfoOverrideArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<UpdateContractInfoOverrideReturn> => {
    return this.fetch(this.url('UpdateContractInfoOverride'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeContractInfoOverride = (
    args: RemoveContractInfoOverrideArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveContractInfoOverrideReturn> => {
    return this.fetch(this.url('RemoveContractInfoOverride'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  isInTokenDirectory = (
    args: IsInTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<IsInTokenDirectoryReturn> => {
    return this.fetch(this.url('IsInTokenDirectory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok,
            featureIndex: <number>_data.featureIndex
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  setTokenDirectoryFeatureIndex = (
    args: SetTokenDirectoryFeatureIndexArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<SetTokenDirectoryFeatureIndexReturn> => {
    return this.fetch(this.url('SetTokenDirectoryFeatureIndex'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  addContractToTokenDirectory = (
    args: AddContractToTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<AddContractToTokenDirectoryReturn> => {
    return this.fetch(this.url('AddContractToTokenDirectory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  removeContractFromTokenDirectory = (
    args: RemoveContractFromTokenDirectoryArgs,
    headers?: object,
    signal?: AbortSignal
  ): Promise<RemoveContractFromTokenDirectoryReturn> => {
    return this.fetch(this.url('RemoveContractFromTokenDirectory'), createHTTPRequest(args, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            ok: <boolean>_data.ok
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }

  refreshTokenDirectory = (headers?: object, signal?: AbortSignal): Promise<RefreshTokenDirectoryReturn> => {
    return this.fetch(this.url('RefreshTokenDirectory'), createHTTPRequest({}, headers, signal)).then(
      res => {
        return buildResponse(res).then(_data => {
          return {
            taskID: <number>_data.taskID
          }
        })
      },
      error => {
        throw WebrpcRequestFailedError.new({ cause: `fetch(): ${error.message || ''}` })
      }
    )
  }
}

const createHTTPRequest = (body: object = {}, headers: object = {}, signal: AbortSignal | null = null): object => {
  const reqHeaders: { [key: string]: string } = { ...headers, 'Content-Type': 'application/json' }
  reqHeaders[WebrpcHeader] = WebrpcHeaderValue

  return {
    method: 'POST',
    headers: reqHeaders,
    body: JSON.stringify(body || {}),
    signal
  }
}

const buildResponse = (res: Response): Promise<any> => {
  return res.text().then(text => {
    let data
    try {
      data = JSON.parse(text)
    } catch (error) {
      let message = ''
      if (error instanceof Error) {
        message = error.message
      }
      throw WebrpcBadResponseError.new({
        status: res.status,
        cause: `JSON.parse(): ${message}: response text: ${text}`
      })
    }
    if (!res.ok) {
      const code: number = typeof data.code === 'number' ? data.code : 0
      throw (webrpcErrorByCode[code] || WebrpcError).new(data)
    }
    return data
  })
}

//
// Errors
//

export class WebrpcError extends Error {
  name: string
  code: number
  message: string
  status: number
  cause?: string

  /** @deprecated Use message instead of msg. Deprecated in webrpc v0.11.0. */
  msg: string

  constructor(name: string, code: number, message: string, status: number, cause?: string) {
    super(message)
    this.name = name || 'WebrpcError'
    this.code = typeof code === 'number' ? code : 0
    this.message = message || `endpoint error ${this.code}`
    this.msg = this.message
    this.status = typeof status === 'number' ? status : 0
    this.cause = cause
    Object.setPrototypeOf(this, WebrpcError.prototype)
  }

  static new(payload: any): WebrpcError {
    return new this(payload.error, payload.code, payload.message || payload.msg, payload.status, payload.cause)
  }
}

// Webrpc errors

export class WebrpcEndpointError extends WebrpcError {
  constructor(
    name: string = 'WebrpcEndpoint',
    code: number = 0,
    message: string = `endpoint error`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcEndpointError.prototype)
  }
}

export class WebrpcRequestFailedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcRequestFailed',
    code: number = -1,
    message: string = `request failed`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcRequestFailedError.prototype)
  }
}

export class WebrpcBadRouteError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRoute',
    code: number = -2,
    message: string = `bad route`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRouteError.prototype)
  }
}

export class WebrpcBadMethodError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadMethod',
    code: number = -3,
    message: string = `bad method`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadMethodError.prototype)
  }
}

export class WebrpcBadRequestError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadRequest',
    code: number = -4,
    message: string = `bad request`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadRequestError.prototype)
  }
}

export class WebrpcBadResponseError extends WebrpcError {
  constructor(
    name: string = 'WebrpcBadResponse',
    code: number = -5,
    message: string = `bad response`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcBadResponseError.prototype)
  }
}

export class WebrpcServerPanicError extends WebrpcError {
  constructor(
    name: string = 'WebrpcServerPanic',
    code: number = -6,
    message: string = `server panic`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcServerPanicError.prototype)
  }
}

export class WebrpcInternalErrorError extends WebrpcError {
  constructor(
    name: string = 'WebrpcInternalError',
    code: number = -7,
    message: string = `internal error`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcInternalErrorError.prototype)
  }
}

export class WebrpcClientDisconnectedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcClientDisconnected',
    code: number = -8,
    message: string = `client disconnected`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcClientDisconnectedError.prototype)
  }
}

export class WebrpcStreamLostError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamLost',
    code: number = -9,
    message: string = `stream lost`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamLostError.prototype)
  }
}

export class WebrpcStreamFinishedError extends WebrpcError {
  constructor(
    name: string = 'WebrpcStreamFinished',
    code: number = -10,
    message: string = `stream finished`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, WebrpcStreamFinishedError.prototype)
  }
}

// Schema errors

export class UnauthorizedError extends WebrpcError {
  constructor(
    name: string = 'Unauthorized',
    code: number = 1000,
    message: string = `Unauthorized access`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, UnauthorizedError.prototype)
  }
}

export class PermissionDeniedError extends WebrpcError {
  constructor(
    name: string = 'PermissionDenied',
    code: number = 1001,
    message: string = `Permission denied`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, PermissionDeniedError.prototype)
  }
}

export class SessionExpiredError extends WebrpcError {
  constructor(
    name: string = 'SessionExpired',
    code: number = 1002,
    message: string = `Session expired`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, SessionExpiredError.prototype)
  }
}

export class MethodNotFoundError extends WebrpcError {
  constructor(
    name: string = 'MethodNotFound',
    code: number = 1003,
    message: string = `Method not found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, MethodNotFoundError.prototype)
  }
}

export class RequestConflictError extends WebrpcError {
  constructor(
    name: string = 'RequestConflict',
    code: number = 1004,
    message: string = `Conflict with target resource`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RequestConflictError.prototype)
  }
}

export class FailError extends WebrpcError {
  constructor(
    name: string = 'Fail',
    code: number = 1005,
    message: string = `Request Failed`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, FailError.prototype)
  }
}

export class GeoblockedError extends WebrpcError {
  constructor(
    name: string = 'Geoblocked',
    code: number = 1006,
    message: string = `Geoblocked region`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, GeoblockedError.prototype)
  }
}

export class TaskFailedError extends WebrpcError {
  constructor(
    name: string = 'TaskFailed',
    code: number = 1007,
    message: string = `Task failed`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, TaskFailedError.prototype)
  }
}

export class DeprecatedError extends WebrpcError {
  constructor(
    name: string = 'Deprecated',
    code: number = 1008,
    message: string = `RPC method is deprecated`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, DeprecatedError.prototype)
  }
}

export class TimeoutError extends WebrpcError {
  constructor(
    name: string = 'Timeout',
    code: number = 2000,
    message: string = `Request timed out`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, TimeoutError.prototype)
  }
}

export class InvalidArgumentError extends WebrpcError {
  constructor(
    name: string = 'InvalidArgument',
    code: number = 2001,
    message: string = `Invalid argument`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, InvalidArgumentError.prototype)
  }
}

export class RequiredArgumentError extends WebrpcError {
  constructor(
    name: string = 'RequiredArgument',
    code: number = 2002,
    message: string = `Required argument missing`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RequiredArgumentError.prototype)
  }
}

export class QueryFailedError extends WebrpcError {
  constructor(
    name: string = 'QueryFailed',
    code: number = 2003,
    message: string = `Query failed`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, QueryFailedError.prototype)
  }
}

export class ValidationFailedError extends WebrpcError {
  constructor(
    name: string = 'ValidationFailed',
    code: number = 2004,
    message: string = `Validation failed`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, ValidationFailedError.prototype)
  }
}

export class RateLimitedError extends WebrpcError {
  constructor(
    name: string = 'RateLimited',
    code: number = 2005,
    message: string = `Rate limited`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, RateLimitedError.prototype)
  }
}

export class NotFoundError extends WebrpcError {
  constructor(
    name: string = 'NotFound',
    code: number = 3000,
    message: string = `Resource not found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}

export class ProjectNotFoundError extends WebrpcError {
  constructor(
    name: string = 'ProjectNotFound',
    code: number = 3002,
    message: string = `Project not found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, ProjectNotFoundError.prototype)
  }
}

export class ChainNotFoundError extends WebrpcError {
  constructor(
    name: string = 'ChainNotFound',
    code: number = 3003,
    message: string = `Chain not found`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, ChainNotFoundError.prototype)
  }
}

export class TokenDirectoryDisabledError extends WebrpcError {
  constructor(
    name: string = 'TokenDirectoryDisabled',
    code: number = 4001,
    message: string = `Token Directory is disabled`,
    status: number = 0,
    cause?: string
  ) {
    super(name, code, message, status, cause)
    Object.setPrototypeOf(this, TokenDirectoryDisabledError.prototype)
  }
}

export enum errors {
  WebrpcEndpoint = 'WebrpcEndpoint',
  WebrpcRequestFailed = 'WebrpcRequestFailed',
  WebrpcBadRoute = 'WebrpcBadRoute',
  WebrpcBadMethod = 'WebrpcBadMethod',
  WebrpcBadRequest = 'WebrpcBadRequest',
  WebrpcBadResponse = 'WebrpcBadResponse',
  WebrpcServerPanic = 'WebrpcServerPanic',
  WebrpcInternalError = 'WebrpcInternalError',
  WebrpcClientDisconnected = 'WebrpcClientDisconnected',
  WebrpcStreamLost = 'WebrpcStreamLost',
  WebrpcStreamFinished = 'WebrpcStreamFinished',
  Unauthorized = 'Unauthorized',
  PermissionDenied = 'PermissionDenied',
  SessionExpired = 'SessionExpired',
  MethodNotFound = 'MethodNotFound',
  RequestConflict = 'RequestConflict',
  Fail = 'Fail',
  Geoblocked = 'Geoblocked',
  TaskFailed = 'TaskFailed',
  Deprecated = 'Deprecated',
  Timeout = 'Timeout',
  InvalidArgument = 'InvalidArgument',
  RequiredArgument = 'RequiredArgument',
  QueryFailed = 'QueryFailed',
  ValidationFailed = 'ValidationFailed',
  RateLimited = 'RateLimited',
  NotFound = 'NotFound',
  ProjectNotFound = 'ProjectNotFound',
  ChainNotFound = 'ChainNotFound',
  TokenDirectoryDisabled = 'TokenDirectoryDisabled'
}

export enum WebrpcErrorCodes {
  WebrpcEndpoint = 0,
  WebrpcRequestFailed = -1,
  WebrpcBadRoute = -2,
  WebrpcBadMethod = -3,
  WebrpcBadRequest = -4,
  WebrpcBadResponse = -5,
  WebrpcServerPanic = -6,
  WebrpcInternalError = -7,
  WebrpcClientDisconnected = -8,
  WebrpcStreamLost = -9,
  WebrpcStreamFinished = -10,
  Unauthorized = 1000,
  PermissionDenied = 1001,
  SessionExpired = 1002,
  MethodNotFound = 1003,
  RequestConflict = 1004,
  Fail = 1005,
  Geoblocked = 1006,
  TaskFailed = 1007,
  Deprecated = 1008,
  Timeout = 2000,
  InvalidArgument = 2001,
  RequiredArgument = 2002,
  QueryFailed = 2003,
  ValidationFailed = 2004,
  RateLimited = 2005,
  NotFound = 3000,
  ProjectNotFound = 3002,
  ChainNotFound = 3003,
  TokenDirectoryDisabled = 4001
}

export const webrpcErrorByCode: { [code: number]: any } = {
  [0]: WebrpcEndpointError,
  [-1]: WebrpcRequestFailedError,
  [-2]: WebrpcBadRouteError,
  [-3]: WebrpcBadMethodError,
  [-4]: WebrpcBadRequestError,
  [-5]: WebrpcBadResponseError,
  [-6]: WebrpcServerPanicError,
  [-7]: WebrpcInternalErrorError,
  [-8]: WebrpcClientDisconnectedError,
  [-9]: WebrpcStreamLostError,
  [-10]: WebrpcStreamFinishedError,
  [1000]: UnauthorizedError,
  [1001]: PermissionDeniedError,
  [1002]: SessionExpiredError,
  [1003]: MethodNotFoundError,
  [1004]: RequestConflictError,
  [1005]: FailError,
  [1006]: GeoblockedError,
  [1007]: TaskFailedError,
  [1008]: DeprecatedError,
  [2000]: TimeoutError,
  [2001]: InvalidArgumentError,
  [2002]: RequiredArgumentError,
  [2003]: QueryFailedError,
  [2004]: ValidationFailedError,
  [2005]: RateLimitedError,
  [3000]: NotFoundError,
  [3002]: ProjectNotFoundError,
  [3003]: ChainNotFoundError,
  [4001]: TokenDirectoryDisabledError
}

export type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>
